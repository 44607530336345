import { css } from "styled-components";
import { scales } from "./types";

export const styles = {
  [scales.h1]: css`
    font-size: 80px;
    line-height: 1.125;
    font-weight: 700;
  `,
  [scales.h2]: css`
    font-size: 64px;
    line-height: 1.18;
    font-weight: 600;
  `,
  [scales.h3]: css`
    font-size: 48px;
    line-height: 1.2;
    font-weight: 600;
  `,
  [scales.h4]: css`
    font-size: 30px;
    line-height: 1.33;
    font-weight: 600;
  `,
  [scales.h5]: css`
    font-size: 20px;
    line-height: 1.5;
    font-weight: 600;
  `,
} as const;
