import styled from "styled-components";

export const StyledItemWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  padding: 11px;

  background-color: ${({ theme }) => theme.colors.neutral800};
  border-radius: ${({ theme }) => theme.radii.medium};

  ${({ theme }) => theme.mediaQueries.laptop} {
    border-radius: 10px;
    padding: 14px;
  }
`;
