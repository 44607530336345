import React from "react";
import { Svg } from "components";
import { SvgProps } from "../types";
import { useThemeContext } from "context";

const Icon: React.FC<SvgProps> = ({ color = "green", ...props }) => {
  const { theme } = useThemeContext();

  return (
    <Svg viewBox="0 0 18 16" {...props} color="transparent">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.57687 1.03695C9.14663 0.275753 8.05075 0.274144 7.61827 1.03407L0.406976 13.7053C-0.0269228 14.4678 0.539233 15.4115 1.41615 15.3876L5.98374 15.2631C6.38535 15.2521 6.75069 15.028 6.94242 14.6749L11.7127 5.89061C11.8979 5.54957 11.8944 5.1372 11.7034 4.79936L9.57687 1.03695ZM14.6846 9.89305C14.2515 9.14855 13.1768 9.14642 12.7407 9.88919L10.5055 13.696C10.0668 14.4431 10.6011 15.3851 11.4675 15.3918L15.9379 15.4268C16.8101 15.4336 17.3585 14.4887 16.9198 13.7348L14.6846 9.89305Z"
        fill={theme.colors[color]}
      />
    </Svg>
  );
};

export default Icon;
