import React from "react";
import { Svg } from "components";
import { SvgProps } from "../types";
import { useThemeContext } from "context";

const Icon: React.FC<SvgProps> = ({ color = "purple", ...props }) => {
  const { theme } = useThemeContext();

  return (
    <Svg viewBox="0 0 24 24" {...props} color="transparent">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19 14V14C17.895 14 17 13.105 17 12V12C17 10.895 17.895 10 19 10V10C20.105 10 21 10.895 21 12V12C21 13.105 20.105 14 19 14Z"
        stroke={theme.colors[color]}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14 19V19C14 20.105 13.105 21 12 21V21C10.895 21 10 20.105 10 19V19C10 17.895 10.895 17 12 17V17C13.105 17 14 17.895 14 19Z"
        stroke={theme.colors[color]}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19 21V21C17.895 21 17 20.105 17 19V19C17 17.895 17.895 17 19 17V17C20.105 17 21 17.895 21 19V19C21 20.105 20.105 21 19 21Z"
        stroke={theme.colors[color]}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 7V7C10.895 7 10 6.105 10 5V5C10 3.895 10.895 3 12 3V3C13.105 3 14 3.895 14 5V5C14 6.105 13.105 7 12 7Z"
        stroke={theme.colors[color]}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17 5V5C17 3.895 17.895 3 19 3V3C20.105 3 21 3.895 21 5V5C21 6.105 20.105 7 19 7V7C17.895 7 17 6.105 17 5Z"
        stroke={theme.colors[color]}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.4102 17.5899L17.5902 13.4099"
        stroke={theme.colors[color]}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.41016 17.5899L10.5902 13.4099"
        stroke={theme.colors[color]}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.4102 10.5899L17.5902 6.40991"
        stroke={theme.colors[color]}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 12V12C10 10.895 10.895 10 12 10V10C13.105 10 14 10.895 14 12V12C14 13.105 13.105 14 12 14V14C10.895 14 10 13.105 10 12Z"
        stroke={theme.colors[color]}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.41016 10.5899L10.5902 6.40991"
        stroke={theme.colors[color]}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 14V14C3.895 14 3 13.105 3 12V12C3 10.895 3.895 10 5 10V10C6.105 10 7 10.895 7 12V12C7 13.105 6.105 14 5 14Z"
        stroke={theme.colors[color]}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 19V19C7 20.105 6.105 21 5 21V21C3.895 21 3 20.105 3 19V19C3 17.895 3.895 17 5 17V17C6.105 17 7 17.895 7 19Z"
        stroke={theme.colors[color]}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 7V7C3.895 7 3 6.105 3 5V5C3 3.895 3.895 3 5 3V3C6.105 3 7 3.895 7 5V5C7 6.105 6.105 7 5 7Z"
        stroke={theme.colors[color]}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
};

export default Icon;
