import React from "react";
import { Svg } from "components";
import { SvgProps } from "../types";
import { useThemeContext } from "context";

const Icon: React.FC<SvgProps> = ({ color = "pink", ...props }) => {
  const { theme } = useThemeContext();
  return (
    <Svg viewBox="0 0 24 25" {...props} color="transparent">
      <path
        d="M13.999 5.16309C14 4.05809 11.76 3.16309 9 3.16309C6.24 3.16309 4.002 4.05909 4 5.16309C4 6.26809 6.238 7.16309 9 7.16309C11.762 7.16309 14 6.26809 14 5.16309"
        stroke={theme.colors[color]}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14 5.16309V12.9231"
        stroke={theme.colors[color]}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4 9.16309C4 10.2681 6.238 11.1631 9 11.1631C11.762 11.1631 14 10.2681 14 9.16309"
        stroke={theme.colors[color]}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.6817 13.4811C21.4387 15.2381 21.4387 18.0881 19.6817 19.8451C17.9247 21.6021 15.0747 21.6021 13.3177 19.8451C11.5608 18.0881 11.5608 15.2381 13.3177 13.4811C15.0747 11.7241 17.9247 11.7241 19.6817 13.4811"
        stroke={theme.colors[color]}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.5 15.3625V17.1205L17.882 17.9635"
        stroke={theme.colors[color]}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.504 14.5881C11.602 14.9431 10.365 15.1631 8.999 15.1631C6.238 15.1631 4 14.2681 4 13.1631"
        stroke={theme.colors[color]}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.445 18.6071C11.548 18.9501 10.339 19.1631 9 19.1631C6.239 19.1631 4.001 18.2681 4 17.1631V5.16309"
        stroke={theme.colors[color]}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
};

export default Icon;
