import React from "react";

import { StyledBlockWrapper } from "./styled";

import { ContentBlockWrapperProps } from "./types";

export const ContentBlockWrapper: React.FC<ContentBlockWrapperProps> = ({ children, $backgroundColor }) => {
  const { offsetHeight } = document.getElementById("header") ?? { offsetHeight: 0 };

  return (
    <StyledBlockWrapper $backgroundColor={$backgroundColor} offsetHeight={offsetHeight}>
      {children}
    </StyledBlockWrapper>
  );
};
