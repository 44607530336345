import { Helmet } from "react-helmet-async";

const IubendaHelper = () => {
  return (
    <Helmet>
      <script type="text/javascript" src="//cdn.iubenda.com/cs/gpp/stub.js" />
      <script type="text/javascript" src="//cdn.iubenda.com/cs/iubenda_cs.js" async />
    </Helmet>
  );
};

export default IubendaHelper;
