import React, { PropsWithChildren } from "react";
import { motion } from "framer-motion";

import { useMatchBreakpoints } from "hooks";

import { changeOpacityOnView } from "theme/animations";

const AnimatedWrapper: React.FC<PropsWithChildren<{}>> = ({ children }) => {
  const { isDesktop } = useMatchBreakpoints();

  return <motion.div {...changeOpacityOnView({ isDesktop })}>{children}</motion.div>;
};

export default AnimatedWrapper;
