import React, { useState } from "react";

import { Text } from "components";
import { StyledHeader, AccordionContent } from "./styled";
import { DropdownArrowIcon } from "components/svg";

import { AccordionProps } from "./types";

const Accordion: React.FC<AccordionProps> = ({ label, iconProps, withHover, children }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div>
      <StyledHeader withHover={withHover} onClick={toggleAccordion}>
        {typeof label === "string" ? <Text textScale="bodyL">{label}</Text> : React.isValidElement(label) && label}

        <DropdownArrowIcon rotate={isOpen ? "-180deg" : "-90deg"} {...iconProps} />
      </StyledHeader>

      <AccordionContent isOpen={isOpen}>{children}</AccordionContent>
    </div>
  );
};

export default Accordion;
