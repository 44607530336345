import React from "react";

import { Skeleton, Box } from "components";
import { PendingRowsProps } from "./types";

const MultiplyPendingRows: React.FC<PendingRowsProps> = ({
  rowsCount = 1,
  rowHeight = "40px",
  rowWidth = "100%",
  ...props
}) => {
  return (
    <Box width="100%">
      {Array.from({ length: rowsCount }).map((_, index) => {
        return (
          <Box key={index} {...props}>
            <Skeleton height={rowHeight} width={rowWidth} />
          </Box>
        );
      })}
    </Box>
  );
};

export default MultiplyPendingRows;
