export const BUTTER_URL = "https://api.buttercms.com/v2";

export const ENDPOINTS_BUTTER_CMS = {
  posts: "/posts/",
  categories: "/categories/",
  searchPosts: "/posts/search/",
};

export const ENDPOINTS = {
  contactUs: "/contact-us/",
  subscribeNewsletter: "/subscribe-newsletter/",
  confirmSubscribeNewsletter: "/subscribe-newsletter/confirm/",
};
