import styled from "styled-components";

export const StyledReleaseWrapper = styled.div`
  position: absolute;
  top: 8px;
  right: 8px;
  border-radius: ${({ theme }) => theme.radii.large};
  border: 1px solid ${({ theme }) => theme.colors.neutral600};
  padding: 6px 12px;
`;
