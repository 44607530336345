import React from "react";
import { Svg } from "components";
import { SvgProps } from "../types";
import { useThemeContext } from "context";

const Icon: React.FC<SvgProps> = ({ color = "pink", ...props }) => {
  const { theme } = useThemeContext();

  return (
    <Svg viewBox="0 0 24 24" color="transparent" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.5 21.1631H5.5C3.567 21.1631 2 19.5961 2 17.6631V17.6631C2 15.7301 3.567 14.1631 5.5 14.1631H18.5C20.433 14.1631 22 15.7301 22 17.6631V17.6631C22 19.5961 20.433 21.1631 18.5 21.1631Z"
        stroke={theme.colors[color]}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.244 17.6621C18.244 17.5271 18.135 17.4181 18 17.4191C17.865 17.4191 17.756 17.5281 17.756 17.6631C17.756 17.7981 17.865 17.9071 18 17.9071C18.135 17.9071 18.244 17.7981 18.244 17.6621"
        stroke={theme.colors[color]}
        strokeWidth="1.625"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.244 17.6621C14.244 17.5271 14.135 17.4181 14 17.4191C13.865 17.4191 13.756 17.5281 13.756 17.6631C13.756 17.7981 13.865 17.9071 14 17.9071C14.134 17.9071 14.244 17.7981 14.244 17.6621"
        stroke={theme.colors[color]}
        strokeWidth="1.625"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.244 17.6621C10.244 17.5271 10.135 17.4181 10 17.4191C9.865 17.4191 9.756 17.5281 9.756 17.6631C9.756 17.7981 9.865 17.9071 10 17.9071C10.134 17.9071 10.244 17.7981 10.244 17.6621"
        stroke={theme.colors[color]}
        strokeWidth="1.625"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.244 17.6621C6.244 17.5271 6.135 17.4181 6 17.4191C5.865 17.4191 5.756 17.5281 5.756 17.6631C5.756 17.7981 5.865 17.9071 6 17.9071C6.134 17.9071 6.244 17.7981 6.244 17.6621"
        stroke={theme.colors[color]}
        strokeWidth="1.625"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19 8.73413V6.13013"
        stroke={theme.colors[color]}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15 8.76709V6.16309"
        stroke={theme.colors[color]}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.122 4.04209C11.294 5.21409 11.294 7.11309 10.122 8.28509C8.95 9.45709 7.051 9.45709 5.879 8.28509C4.707 7.11309 4.707 5.21409 5.879 4.04209C7.051 2.87009 8.951 2.87009 10.122 4.04209"
        stroke={theme.colors[color]}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19 6.12998L11 6.16298"
        stroke={theme.colors[color]}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
};

export default Icon;
