import React from "react";
import { Svg } from "components";
import { SvgProps } from "../types";
import { useThemeContext } from "context";

const Icon: React.FC<SvgProps> = ({ ...props }) => {
  const { theme } = useThemeContext();

  return (
    <Svg viewBox="0 0 24 24" color="transparent" stroke={theme.colors.neutral600} {...props}>
      <circle cx="12.0586" cy="12.0588" r="7.06194" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M21.0033 21.0034L17.0517 17.0518" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </Svg>
  );
};

export default Icon;
