import styled from "styled-components";
import { StyledIconBox } from "components";

export const StyledItemWrapper = styled.div<{ isOpen: boolean }>`
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: ${({ theme, isOpen }) => (isOpen ? theme.colors.neutral900 : "transparent")};
  border-radius: ${({ theme }) => theme.radii.semiMedium};
  border: 1px solid ${({ theme }) => theme.colors.neutral600};
  transition: all 0.3s ease;
  padding: 24px;

  ${({ theme }) => theme.mediaQueries.laptop} {
    padding: 40px;
  }
`;

export const StyledArrowIconBox = styled(StyledIconBox)`
  margin-inline-end: 0;
  cursor: pointer;
  background-color: ${({ theme, color }) => theme.colors[color]};

  &:hover {
    background-color: ${({ theme, color }) => `${theme.colors[color]}4d`};
  }
`;
