import styled from "styled-components";
import { space, typography, layout, opacity, border, shadow, variant } from "styled-system";
import { scaleVariants, variantStyles } from "./theme";

import { ButtonProps } from "./types";
import Text from "components/text";
import { Svg } from "components/svg";
import { scaleAnimation } from "theme";

const StyledButton = styled.button<ButtonProps>`
  border-radius: ${({ theme }) => theme.radii.large};

  position: relative;
  align-items: center;
  cursor: pointer;
  display: inline-flex;
  font-family: inherit;
  justify-content: center;
  outline: 0;
  width: fit-content;
  border: 0;

  &:focus {
    outline: unset;
  }

  ${Text} {
    color: ${({ theme }) => theme.colors.neutral0};
  }

  ${Svg} {
    fill: ${({ theme }) => theme.colors.neutral0};
  }

  &:not([disabled]):hover {
    background-color: ${({ theme }) => theme.colors.primaryHover};
    border: 2px solid ${({ theme }) => theme.colors.primaryHover};
  }

  ${scaleAnimation}

  &:disabled {
    background-color: ${({ theme }) => theme.colors.neutral800};
    border: 2px solid ${({ theme }) => theme.colors.neutral800};
    color: ${({ theme }) => theme.colors.neutral600};
    cursor: not-allowed;
    ${Text} {
      color: ${({ theme }) => theme.colors.neutral600};
    }

    ${Svg} {
      fill: ${({ theme }) => theme.colors.neutral600};
    }
  }

  ${variant({
    prop: "scale",
    variants: scaleVariants,
  })};

  ${variantStyles};

  ${space}
  ${typography}
  ${layout}
  ${opacity}
  ${border}
  ${shadow}
`;

export default StyledButton;
