import React from "react";

import { MobileMenuWrapper, StyledMotion } from "./styled";
import { Accordion, Button, Heading, NavigationMenu, Link } from "components";

import { useTranslation } from "context";
import { useHideOverflow } from "hooks";

import { NAVIGATE_LINKS } from "components/header/constants";
import { slideVariants } from "./animations";
import { LINKS } from "configs";

import { MobileMenuProps } from "./types";

export const MobileMenu: React.FC<MobileMenuProps> = ({ isOpen, setIsOpen }) => {
  const { t, getLocalizedPath } = useTranslation();

  useHideOverflow({ trigger: isOpen });

  const { offsetHeight } = document.getElementById("header") ?? { offsetHeight: 0 };

  const handleCloseMobileMenu = () => {
    setIsOpen(false);
  };

  return (
    <StyledMotion
      initial="hidden"
      animate={isOpen ? "visible" : "hidden"}
      variants={slideVariants}
      offsetHeight={offsetHeight}
    >
      <MobileMenuWrapper offsetHeight={offsetHeight}>
        <div>
          <Accordion
            withHover
            label={
              <Heading as="h5" scale="h5" py="12px">
                {t("Services")}
              </Heading>
            }
            iconProps={{ width: "30px" }}
          >
            <NavigationMenu onClick={handleCloseMobileMenu} />
          </Accordion>

          {NAVIGATE_LINKS.map(({ title, path }) => {
            return (
              <Link
                key={title}
                py="12px"
                fontSize="20px"
                width="100%"
                textAlign="center"
                href={getLocalizedPath(`/${path}`)}
                onClick={handleCloseMobileMenu}
              >
                {t(title)}
              </Link>
            );
          })}
        </div>

        <Button my="14px" as="a" external href={LINKS.zertMain} width="100%" onClick={handleCloseMobileMenu}>
          {t("Sign Up")}
        </Button>
      </MobileMenuWrapper>
    </StyledMotion>
  );
};
