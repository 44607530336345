import React from "react";
import { Svg } from "components";
import { SvgProps } from "../types";
import { useThemeContext } from "context";

const Icon: React.FC<SvgProps> = ({ color = "primary", ...props }) => {
  const { theme } = useThemeContext();
  return (
    <Svg viewBox="0 0 24 24" {...props} color="transparent">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 6.96309V6.96309C3 7.56309 3.3 8.06309 3.8 8.46309L9.8 12.5631C11.2 13.4631 12.9 13.4631 14.3 12.5631L20.3 8.56309C20.7 8.06309 21 7.56309 21 6.96309V6.96309C21 5.96309 20.2 5.16309 19.2 5.16309H4.8C3.8 5.16309 3 5.96309 3 6.96309Z"
        stroke={theme.colors[color]}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3 7.16309V17.1631C3 18.2631 3.9 19.1631 5 19.1631H19C20.1 19.1631 21 18.2631 21 17.1631V7.16309"
        stroke={theme.colors[color]}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.58984 18.575L9.68384 12.481"
        stroke={theme.colors[color]}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.3594 12.5229L20.4114 18.5749"
        stroke={theme.colors[color]}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
};

export default Icon;
