import { Column, Heading, Text, StyledIconBox } from "components";
import { StyledCardWrapper } from "../";
// Context
import { useTranslation } from "context";
// Types
import { InfoCardProps } from "./types";

const InfoCard: React.FC<InfoCardProps> = ({
  title,
  description,
  icon: Icon,
  color,
  backgroundImg,
  position,
  backgroundSize,
  textScale,
}) => {
  const { t } = useTranslation();

  return (
    <StyledCardWrapper
      p={{ _: "0px", laptop: "40px" }}
      backgroundPosition={position}
      backgroundImg={backgroundImg}
      backgroundSize={backgroundSize}
    >
      <Column width="100%" alignItems="start">
        <StyledIconBox color={color}>
          <Icon color={color} />
        </StyledIconBox>

        <Heading mt={{ _: "32px", laptop: "24px" }} scale="h5" as="h3" color="neutral200" $fontWeight="semiBold">
          {t(title)}
        </Heading>

        <Text my="24px" textScale={textScale ? textScale : "bodyM"} color="neutral400">
          {t(description)}
        </Text>
      </Column>
    </StyledCardWrapper>
  );
};

export default InfoCard;
