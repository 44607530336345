import styled from "styled-components";

export const StyledContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.neutral900};
  background-image: url("/images/circles/blue.svg"), url("/images/circles/purple.svg");
  background-repeat: no-repeat;
  background-position:
    -400px 0,
    0 400px;

  ${({ theme }) => theme.mediaQueries.laptop} {
    background-position:
      -50% -60%,
      50% -50%;
  }
`;

export const FooterWrapper = styled.div`
  padding: 52px 0 54px;

  ${({ theme }) => theme.mediaQueries.laptop} {
    padding: 72px 0 55px;
  }
`;
