import debounce from "lodash/debounce";
import { ChangeEvent, useEffect, useRef, useState } from "react";

export const useHandleInputDebounce = (
  initialState: string,
  callback: (val: string) => void,
  timeout: number = 1000,
) => {
  const [value, setValue] = useState(initialState);

  useEffect(() => {
    return () => {
      debounceHandler.cancel();
    };
  }, []);

  const clearValue = () => {
    setValue("");
    debounceCallback("");
  };

  const debounceCallback = (val: string) => {
    callback(val);
  };

  const debounceHandler = useRef(debounce(debounceCallback, timeout)).current;

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setValue(value);
    debounceHandler(value);
  };

  return { value, handleInputChange, clearValue };
};
