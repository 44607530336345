import styled from "styled-components";

export const DropdownSelectWrapper = styled.div`
  cursor: pointer;
  user-select: none;

  &:hover {
    transition: all 0.3s ease;

    & > div {
      & > p,
      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        color: ${({ theme }) => theme.colors.primaryHover};
      }
      & > svg {
        transform: rotateZ(-180deg);
        path {
          stroke: ${({ theme }) => theme.colors.primaryHover};
        }
      }
    }
  }
`;
