import React from "react";
import { Svg } from "components";
import { SvgProps } from "../types";
import { useThemeContext } from "context";

const Icon: React.FC<SvgProps> = ({ color = "red", ...props }) => {
  const { theme } = useThemeContext();

  return (
    <Svg viewBox="0 0 24 24" color="transparent" {...props}>
      <path
        d="M9.67969 4.94188C12.2687 2.35188 16.4677 2.35288 19.0577 4.94188C21.6477 7.53088 21.6477 11.7299 19.0577 14.3199"
        stroke={theme.colors[color]}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.264 14.3678C16.264 18.0308 13.295 20.9998 9.632 20.9998C5.969 20.9998 3 18.0308 3 14.3678C3 10.7048 5.969 7.73584 9.632 7.73584C13.295 7.73584 16.264 10.7048 16.264 14.3678"
        stroke={theme.colors[color]}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.8691 17.0668H7.70312V14.3528H10.8681C11.6171 14.3528 12.2251 14.9608 12.2251 15.7098V15.7098C12.2251 16.4588 11.6181 17.0668 10.8691 17.0668Z"
        stroke={theme.colors[color]}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.70312 14.353V11.554H10.5251C11.2981 11.554 11.9241 12.18 11.9241 12.953V12.953C11.9241 13.726 11.2981 14.352 10.5251 14.352"
        stroke={theme.colors[color]}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.47266 10.7509V11.5529"
        stroke={theme.colors[color]}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.47266 17.985V17.081"
        stroke={theme.colors[color]}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
};

export default Icon;
