import React from "react";

import { StyledMenu, StyledSpan } from "./styled";

import { BurgerMenuProps } from "./types";

export const BurgerMenu: React.FC<BurgerMenuProps> = ({ isOpen, setIsOpen }) => {
  const handlerMenuOpen = () => {
    setIsOpen(!isOpen);
  };

  return (
    <StyledMenu onClick={handlerMenuOpen} isOpen={isOpen}>
      <StyledSpan width={!isOpen ? "12px" : "0px"} $backgroundColor="neutral200" top="9px" />
      <StyledSpan width={!isOpen ? "8px" : "0px"} $backgroundColor="neutral200" bottom="4px" />
    </StyledMenu>
  );
};
