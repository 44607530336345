import styled from "styled-components";

export const StyledContainer = styled.div<{ imgUrl: string }>`
  display: flex;
  flex: 1;
  padding-top: 70px;
  padding-bottom: 70px;

  ${({ theme }) => theme.mediaQueries.laptop} {
    background-repeat: no-repeat;
    background-image: ${({ imgUrl }) => `url(${imgUrl})`};
    background-position: 50% 240px;
    background-size: 100% 61%;
    padding-top: 120px;
    padding-bottom: 120px;
  }
`;

export const StyledTab = styled.div<{ active: boolean }>`
  display: ${({ active }) => (active ? "block" : "none")};
  width: 100%;
`;
