import React, { useEffect, useRef } from "react";
import Lottie, { LottieRefCurrentProps } from "lottie-react";

import { Flex } from "components";

import loadingAnimation from "assets/animations/loading.json";

const Loading: React.FC<{ isAnimate?: boolean }> = ({ isAnimate = true }) => {
  const animationRef = useRef<LottieRefCurrentProps | null>(null);

  useEffect(() => {
    if (animationRef.current) {
      if (!isAnimate) {
        animationRef.current?.goToAndStop(30, true);
      } else {
        animationRef.current?.goToAndPlay(30, true);
      }
    }
  }, [isAnimate]);
  return (
    <Flex justifyContent="center" alignItems="center" height="100vh">
      <Lottie lottieRef={animationRef} autoplay={false} animationData={loadingAnimation} />
    </Flex>
  );
};

export default Loading;
