import styled from "styled-components";

export const StyledTagsWrapper = styled.div`
  display: flex;
  gap: 6px;
  overflow-y: auto;

  ::-webkit-scrollbar {
    height: 0;
  }
`;
