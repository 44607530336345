import { useState } from "react";

import { useModal, useTranslation } from "context";

import { isErrorResult } from "services";
import { subscribeNewsletter } from "services/requests";
import { initialValues } from "components/inputs/input-email/hooks";

const useSubscribeNewsletter = () => {
  const [isLoading, setIsLoading] = useState(false);

  const { t } = useTranslation();

  const { openModal, closeModal } = useModal();

  const onShowModal = () => {
    openModal({
      modalName: "actionModal",
      props: {
        title: t("Thank you"),
        description: t("Please check your email and follow link to complete subscription."),
        buttonText: t("OK"),
        onButtonClick: closeModal,
      },
    });
  };

  const showErrorModal = () => {
    openModal({
      modalName: "actionModal",
      props: {
        title: t("Subscription failed"),
        description: t("Something went wrong. Please, try again"),
        buttonText: t("OK"),
        onButtonClick: closeModal,
      },
    });
  };

  const handleSubscribe = (values: typeof initialValues) => {
    subscribeNewsletter(values).then(res => {
      if (!isErrorResult(res) && res.status === "ok") {
        onShowModal();
        setIsLoading(false);
      } else {
        showErrorModal();
        setIsLoading(false);
      }
    });
  };

  return { isLoading, handleSubscribe };
};

export default useSubscribeNewsletter;
