import { DefaultTheme } from "styled-components";

export const getTooltipStyles = (theme: DefaultTheme) => {
  return {
    tooltip: {
      padding: "1px",
      width: "fit-content",
      border: "none",
      background: theme.gradients.navigationMenu,
      boxShadow: theme.shadows.dropdown,
      borderRadius: theme.radii.semiMedium,
    },
    arrow: {
      display: "none",
    },
  };
};
