import React from "react";
import { Svg } from "components";
import { SvgProps } from "../types";
import { useThemeContext } from "context";

const Icon: React.FC<SvgProps> = ({ color = "neutral500", ...props }) => {
  const { theme } = useThemeContext();

  return (
    <Svg viewBox="0 0 24 24" {...props} color="transparent">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.583 6.37312C21.354 5.36912 20.676 4.57712 19.814 4.30512C18.255 3.81812 12 3.81812 12 3.81812C12 3.81812 5.748 3.81812 4.186 4.30512C3.327 4.57312 2.649 5.36512 2.417 6.37312C2 8.19512 2 12.0001 2 12.0001C2 12.0001 2 15.8051 2.417 17.6271C2.646 18.6311 3.324 19.4231 4.186 19.6951C5.748 20.1821 12 20.1821 12 20.1821C12 20.1821 18.255 20.1821 19.814 19.6951C20.673 19.4271 21.351 18.6351 21.583 17.6271C22 15.8051 22 12.0001 22 12.0001C22 12.0001 22 8.19512 21.583 6.37312Z"
        stroke={theme.colors[color]}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.0039 15L15.1999 12L10.0039 9V15Z"
        stroke={theme.colors[color]}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
};

export default Icon;
