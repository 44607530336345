import { css, DefaultTheme } from "styled-components";

import { ButtonProps, scales } from "./types";

export interface ThemedProps extends ButtonProps {
  theme: DefaultTheme;
}

export const variantStyles = ({ theme, variant }: ThemedProps) => {
  return {
    primary: css`
      background-color: ${theme.colors.primary};
      border: 2px solid ${theme.colors.primary};
    `,

    outline: css`
      background-color: ${theme.colors.transparent};
      border: 2px solid ${theme.colors.neutral600};
    `,
  }[variant!];
};

export const scaleVariants = {
  [scales.LG]: {
    padding: "16px 46px",
    minWidth: 165,
  },
  [scales.MD]: {
    padding: "10px 24px",
    minWidth: 108,
  },
  [scales.SM]: {
    padding: "10px",
    minWidth: 48,
  },
};
