import React, { cloneElement, useState } from "react";

import { AnimatedTitleWrapper, InputError, InputIcon, StyledInputGroup } from "./styled";

import { InputGroupProps } from "./types";
import { scales as inputScales } from "components/inputs/input/types";
import { Box, Text } from "components";

export const InputGroup: React.FC<InputGroupProps> = ({
  scale = inputScales.MD,
  startIcon,
  endIcon,
  children,
  error,
  label,
  isTouched,
  isShowError = true,
  ...props
}) => {
  const [isFocused, setIsFocused] = useState(false);

  return (
    <StyledInputGroup
      scale={scale}
      width="100%"
      hasStartIcon={!!startIcon}
      hasEndIcon={!!endIcon}
      onFocus={() => setIsFocused(true)}
      onBlur={() => setIsFocused(false)}
      {...props}
    >
      {label && (
        <AnimatedTitleWrapper
          scale={scale}
          hasStartIcon={!!startIcon}
          hasEndIcon={!!endIcon}
          isFocused={children.props.value ? true : isFocused}
        >
          <Text textScale="captionS" color="neutral600">
            {label}
          </Text>
        </AnimatedTitleWrapper>
      )}

      <Box position="relative">
        {startIcon && <InputIcon scale={scale}>{startIcon}</InputIcon>}

        {cloneElement(children, { scale, error, isTouched })}

        {endIcon && (
          <InputIcon scale={scale} isEndIcon>
            {endIcon}
          </InputIcon>
        )}
      </Box>

      {isShowError && (
        <InputError scale={scale} textScale="bodyS" hasStartIcon={!!startIcon} hasEndIcon={!!endIcon}>
          {error && isTouched ? error : " "}
        </InputError>
      )}
    </StyledInputGroup>
  );
};
