import React from "react";

import { Flex, Column, FlexGap, Link, StyledLink, LanguageSelectorWithCurrentLanguage } from "components";
import { ColumnLinks, Logo, Newsletter, SocialLinks, IubendaHelper } from "components/footer/components";
import { StyledDropdown } from "../styled";

import { useTranslation } from "context";

import { NAVIGATION_MENU_ITEMS } from "components/header/components/navigation-menu/constants";
import { NAVIGATE_LINKS } from "components/header/constants";
import { ROUTES } from "navigation/routes";

export const MobileGrid: React.FC = () => {
  const { t } = useTranslation();

  return (
    <>
      <IubendaHelper />
      <div>
        <Newsletter mb="54px" />

        <FlexGap gap="8px">
          <Flex justifyContent="center" width="50%">
            <ColumnLinks title="Services" links={NAVIGATION_MENU_ITEMS} />
          </Flex>

          <Flex justifyContent="center" width="50%">
            <ColumnLinks title="Company" links={NAVIGATE_LINKS} />
          </Flex>
        </FlexGap>

        <SocialLinks justifyContent="center" my="58px" gap="32px" />

        <StyledDropdown>
          <LanguageSelectorWithCurrentLanguage />
        </StyledDropdown>

        <Logo my="32px" />

        <FlexGap gap="8px">
          <Column justifyContent="center" width="50%">
            <Link target="_blank" href={`/${ROUTES.termsAndConditions}`} color="neutral500" external>
              {t("Terms and Conditions")}
            </Link>
            <StyledLink as="a" className="iubenda-cs-uspr-link" color="neutral500">
              {t("Notice at Collection")}
            </StyledLink>
          </Column>

          <Column justifyContent="center" width="50%">
            <Link target="_blank" href={`/${ROUTES.privacyPolicy}`} color="neutral500" external>
              {t("Privacy Policy")}
            </Link>
            <StyledLink as="a" className="iubenda-cs-preferences-link" color="neutral500">
              {t("Your Privacy Choices")}
            </StyledLink>
          </Column>
        </FlexGap>
      </div>
    </>
  );
};
