import React from "react";
import { Svg } from "components";
import { SvgProps } from "../types";
import { useThemeContext } from "context";

const Icon: React.FC<SvgProps> = ({ color = "pink", ...props }) => {
  const { theme } = useThemeContext();
  return (
    <Svg viewBox="0 0 24 24" {...props} color="transparent">
      <path
        d="M12.9411 11.2231L5.87109 18.2931"
        stroke={theme.colors[color]}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.63344 13.1622L3.17044 12.3442C2.78944 12.2542 2.65544 11.7812 2.93144 11.5042L5.68544 8.75018C5.87044 8.56518 6.12144 8.45918 6.38444 8.45718L9.48944 8.43018"
        stroke={theme.colors[color]}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.8791 9.1382L20.6871 5.2502C20.9071 4.1922 19.9711 3.2562 18.9131 3.4762L15.0251 4.2842C13.8801 4.5222 12.8301 5.0892 12.0041 5.9162L8.81407 9.1052C7.59707 10.3222 6.80507 11.9002 6.55507 13.6032L6.54407 13.6762C6.38607 14.7632 6.75007 15.8602 7.52607 16.6372V16.6372C8.30207 17.4132 9.40007 17.7772 10.4871 17.6182L10.5601 17.6072C12.2631 17.3582 13.8411 16.5652 15.0581 15.3482L18.2471 12.1592C19.0741 11.3332 19.6411 10.2832 19.8791 9.1382V9.1382Z"
        stroke={theme.colors[color]}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.3984 4.87695C15.7094 6.45295 17.7084 8.45195 19.2844 10.763"
        stroke={theme.colors[color]}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11 17.5303L11.818 20.9933C11.908 21.3743 12.381 21.5083 12.658 21.2323L15.412 18.4783C15.597 18.2933 15.703 18.0423 15.705 17.7793L15.732 14.6743"
        stroke={theme.colors[color]}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
};

export default Icon;
