import React from "react";
import { StyledTag } from "./styled";
import { Text } from "components";

const Tag: React.FC<{ title: string }> = ({ title }) => {
  return (
    <StyledTag>
      <Text textScale="captionL" textTransform="uppercase">
        {title}
      </Text>
    </StyledTag>
  );
};

export default Tag;
