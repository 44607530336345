import React from "react";
import { Helmet } from "react-helmet-async";
import { useLocation } from "react-router-dom";

import { EN, getCustomMeta, languageList } from "configs";
import { Container } from "../container";
import { useTranslation } from "context";
import { PageProps } from "../types";
import { cutLocaleFromRoute, removeTrailingSlashIfExists } from "utils";

export const PageMeta: React.FC<PageProps> = ({
  title: titleProps,
  description: descriptionProps,
  image: imageProps,
}) => {
  const { pathname } = useLocation();
  const { t, currentLanguage } = useTranslation();
  const locale = currentLanguage.locale === EN.locale ? "" : currentLanguage.locale;

  const { title, description, image, keywords } = getCustomMeta(pathname, locale, t);
  const correctPath = cutLocaleFromRoute(pathname);

  return (
    <Helmet>
      <meta name="description" content={description} />
      <meta name="keywords" content={keywords} />

      <meta property="twitter:title" content={title} />
      <meta property="twitter:description" content={description} />
      <meta property="twitter:image" content={image} />

      <meta property="og:title" content={titleProps ?? title} />
      <meta property="og:description" content={descriptionProps ?? description} />
      <meta property="og:image" content={imageProps ?? image} />
      <meta property="og:locale" content={currentLanguage.locale} />

      <link rel="canonical" href={`${process.env.REACT_APP_URL}${removeTrailingSlashIfExists(pathname)}`} />
      <link
        rel="alternate"
        href={`${process.env.REACT_APP_URL}${removeTrailingSlashIfExists(pathname)}`}
        hrefLang="x-default"
      />

      {languageList.map(({ locale }) => {
        const languageCode = locale === EN.locale ? "" : `/${locale}`;
        const href = `${process.env.REACT_APP_URL}${languageCode}${removeTrailingSlashIfExists(correctPath)}`;

        return <link key={locale} rel="alternate" href={href} hrefLang={locale} />;
      })}

      <title>{titleProps ?? title}</title>
    </Helmet>
  );
};

export const Page: React.FC<PageProps> = ({ title, description, image, children, ...props }) => {
  return (
    <>
      <PageMeta title={title} description={description} image={image} />
      <Container {...props}>{children}</Container>
    </>
  );
};
