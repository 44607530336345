import { AxiosResponse } from "axios";

import { getInstance } from "./axios";
import { ErrorResult } from "./types";

const axiosInstance = getInstance();

export const makeApiRequest = async <Response>({ ...config }) => {
  try {
    const result = (await axiosInstance({ ...config })) as AxiosResponse<Response>;

    return result.data;
  } catch (error) {
    const errorObj: ErrorResult = {
      message: "Error",
      isError: true,
    };

    return errorObj;
  }
};

export const isErrorResult = (result: unknown): result is ErrorResult => {
  return (
    typeof result === "object" &&
    result !== null &&
    "isError" in result &&
    (result as Record<string, unknown>).isError === true
  );
};
