import styled from "styled-components";

import { Svg } from "components";
import { scaleAnimation } from "theme";

export const StyledIcon = styled.div`
  cursor: pointer;

  &:hover {
    ${Svg} {
      path {
        stroke: ${({ theme }) => theme.colors.primaryHover};
      }
    }
  }

  ${scaleAnimation}
`;
