import { Language } from "context/language-context/types";

export const EN: Language = { locale: "en-US", language: "English", code: "en", translate: "English" };
export const UA: Language = { locale: "uk-UA", language: "Ukrainian", code: "ua", translate: "Українська" };
export const RU: Language = { locale: "ru-RU", language: "Russian", code: "ru", translate: "Русский" };
export const KR: Language = { locale: "ko-KR", language: "Korean", code: "ko", translate: "한국어" };
export const JP: Language = { locale: "ja-JP", language: "Japanese", code: "ja", translate: "日本語" };
export const DE: Language = { locale: "de-DE", language: "German", code: "de", translate: "Deutsch" };
export const TR: Language = { locale: "tr-TR", language: "Turkish", code: "tr", translate: "Türkçe" };
export const FR: Language = { locale: "fr-FR", language: "French", code: "fr", translate: "Français" };
export const CN: Language = { locale: "zh-CN", language: "Chinese", code: "zh", translate: "简体中文" };
export const SE: Language = { locale: "sv-SE", language: "Swedish", code: "sv", translate: "Svenska" };
export const BG: Language = { locale: "bg-BG", language: "Bulgarian", code: "bg", translate: "Български" };
export const PL: Language = { locale: "pl-PL", language: "Polish", code: "pl", translate: "Polski" };
export const NL: Language = { locale: "nl-NL", language: "Dutch", code: "nl", translate: "Nederlands" };
export const MY: Language = { locale: "ms-MY", language: "Malaysian", code: "ms", translate: "Melayu" };
export const SK: Language = { locale: "sk-SK", language: "Slovak", code: "sk", translate: "Slovenčina" };
export const FI: Language = { locale: "fi-FI", language: "Finnish", code: "fi", translate: "Suomi" };
export const ES: Language = { locale: "es-ES", language: "Spanish", code: "es", translate: "Español" };
export const BR: Language = { locale: "pt-BR", language: "Portuguese", code: "pt", translate: "Português" };
export const SL: Language = { locale: "sl-SL", language: "Slovene", code: "sl", translate: "Slovenščina" };
export const ID: Language = { locale: "id-ID", language: "Indonesian", code: "id", translate: "Bahasa Indonesia" };
export const NO: Language = { locale: "no-NO", language: "Norwegian", code: "no", translate: "Norsk" };
export const IT: Language = { locale: "it-IT", language: "Italian", code: "it", translate: "Italiano" };
export const GR: Language = { locale: "el-GR", language: "Greek", code: "el", translate: "Greek" };
export const IL: Language = { locale: "iw-IL", language: "Hebrew", code: "iw", translate: "עברית" };
export const AR: Language = { locale: "ar", language: "Arabic", code: "ar", translate: "العربية" };
export const DK: Language = { locale: "da-DK", language: "Danish", code: "da", translate: "Dansk" };

export const languages = {
  [AR.locale]: AR,
  [BG.locale]: BG,
  [CN.locale]: CN,
  [DK.locale]: DK,
  [NL.locale]: NL,
  [EN.locale]: EN,
  [FI.locale]: FI,
  [FR.locale]: FR,
  [DE.locale]: DE,
  [GR.locale]: GR,
  [IL.locale]: IL,
  [ID.locale]: ID,
  [IT.locale]: IT,
  [JP.locale]: JP,
  [KR.locale]: KR,
  [MY.locale]: MY,
  [NO.locale]: NO,
  [PL.locale]: PL,
  [BR.locale]: BR,
  [RU.locale]: RU,
  [SK.locale]: SK,
  [SL.locale]: SL,
  [ES.locale]: ES,
  [SE.locale]: SE,
  [TR.locale]: TR,
  [UA.locale]: UA,
};

export const languageList = Object.values(languages);
