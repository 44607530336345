import React from "react";
import { Svg } from "components";
import { SvgProps } from "../types";
import { useThemeContext } from "context";

const Icon: React.FC<SvgProps> = ({ color = "green", ...props }) => {
  const { theme } = useThemeContext();
  return (
    <Svg viewBox="0 0 24 24" {...props} color="transparent">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.13575 15.1611C2.95475 14.8561 2.95475 14.4701 3.13575 14.1651C5.04075 10.9421 8.52075 8.16309 12.0007 8.16309C15.4807 8.16309 18.9597 10.9421 20.8647 14.1641C21.0457 14.4701 21.0457 14.8571 20.8647 15.1631C18.9597 18.3841 15.4807 21.1631 12.0007 21.1631C8.52075 21.1631 5.04075 18.3841 3.13575 15.1611Z"
        stroke={theme.colors[color]}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.122 12.5421C15.294 13.7141 15.294 15.6131 14.122 16.7851C12.95 17.9571 11.051 17.9571 9.879 16.7851C8.707 15.6131 8.707 13.7141 9.879 12.5421C11.051 11.3701 12.951 11.3701 14.122 12.5421"
        stroke={theme.colors[color]}
        strokeWidth="1.4286"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 2.66309V5.16309"
        stroke={theme.colors[color]}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5 4.66309L6.68 6.66309"
        stroke={theme.colors[color]}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.0003 4.66309L17.3203 6.66309"
        stroke={theme.colors[color]}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
};

export default Icon;
