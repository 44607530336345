import styled, { css } from "styled-components";

export const StyledHeader = styled.div<{ withHover?: boolean }>`
  display: flex;
  justify-content: space-between;
  cursor: pointer;

  ${({ withHover }) =>
    withHover &&
    css`
      &:hover {
        & > p,
        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
          color: ${({ theme }) => theme.colors.primaryHover};
        }
        & > svg {
          path {
            stroke: ${({ theme }) => theme.colors.primaryHover};
          }
        }
      }
    `}
`;

export const AccordionContent = styled.div<{ isOpen: boolean }>`
  max-height: ${({ isOpen }) => (isOpen ? "100vh" : "0")};
  opacity: ${({ isOpen }) => (isOpen ? "1" : "0")};
  transition: all 0.3s ease;
  overflow: hidden;
`;
