import styled from "styled-components";

import { Flex, StyledIconBox } from "components";

export const StyledValueCardWrapper = styled(Flex)<{
  backgroundPosition: string;
  backgroundImg: string;
  backgroundSize?: string;
}>`
  width: 100%;
  border: 1px solid ${({ theme }) => theme.colors.neutral700};
  background-repeat: no-repeat;
  background-color: ${({ theme }) => theme.colors.neutral900};
  border-radius: 10px;
  background-image: ${({ backgroundImg }) => backgroundImg};
  background-position: ${({ backgroundPosition }) => backgroundPosition};
  background-size: ${({ backgroundSize }) => backgroundSize};

  ${({ theme }) => theme.mediaQueries.laptop} {
    border-radius: ${({ theme }) => theme.radii.semiMedium};
  }
`;

export const StyledValueCardIconBox = styled(StyledIconBox)`
  width: 20px;
  height: 20px;
  margin-inline-end: 0px;

  ${({ theme }) => theme.mediaQueries.laptop} {
    width: 40px;
    height: 40px;
  }
`;
