import { EN, LOCAL_STORAGE_KEYS, REGEX } from "configs";

export const fetchLocale = async (locale: string) => {
  try {
    if (locale === EN.locale) {
      const response = await fetch(`/locales/locales.json`);

      const data = (await response.json()) as Promise<object>;

      return data;
    } else {
      const response = await fetch(`/${locale}/locales/locales.json`);

      const data = (await response.json()) as Promise<object>;

      return data;
    }
  } catch (e) {
    console.error(`Failed to fetch locale ${EN.locale}`, e);

    return null;
  }
};

export const getLanguageCodeFromLS = () => {
  return localStorage.getItem(LOCAL_STORAGE_KEYS.language) ?? EN.locale;
};

export const translatedTextIncludesVariable = (translatedText: string) =>
  !!translatedText?.match(REGEX.includesVariableRegex);
