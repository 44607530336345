import React from "react";

import { Heading, Button, Image, Text } from "components";
import { StyledModalWrapper } from "./styled";
import CloseModalWrapper from "../close-modal-wrapper";

import { useModal, useTranslation } from "context";
import { useMatchBreakpoints } from "hooks";

const ActionModal: React.FC = () => {
  const { isDesktop } = useMatchBreakpoints();
  const { t } = useTranslation();

  const {
    closeModal,
    onModalClose,

    props: {
      title = t("Thank you"),
      description = t("For contacting us, we will reply promptly once your message is received"),
      buttonText = t("Home Page"),
      onButtonClick,
    } = {},
  } = useModal();

  const handleModalClose = () => {
    onModalClose?.();
    closeModal();
  };

  return (
    <StyledModalWrapper>
      {isDesktop && <CloseModalWrapper closeModalHandler={handleModalClose} />}

      <Image
        src={"/images/modal/zert.webp"}
        width={{ _: "83px", laptop: "111px" }}
        height={{ _: "97px", laptop: "130px" }}
        aspectRatio={0.85}
        mt="24px"
      />

      <Heading
        mt={{ _: "32px", laptop: "40px" }}
        scale={{ _: "h4", laptop: "h3" }}
        as="h4"
        color="neutral200"
        textAlign="center"
      >
        {title}
      </Heading>

      <Text
        px={{ _: "15px", laptop: "70px" }}
        mt="18px"
        textScale={{ _: "bodyS", laptop: "bodyL" }}
        color="neutral500"
        textAlign="center"
      >
        {description}
      </Text>

      <Button
        mt={{ _: "32px", laptop: "36px" }}
        px={{ _: "auto", laptop: "46px" }}
        scale="lg"
        width={{ _: "100%", laptop: "auto" }}
        onClick={onButtonClick}
      >
        {buttonText}
      </Button>
    </StyledModalWrapper>
  );
};

export default ActionModal;
