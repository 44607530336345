import React from "react";
import { createPortal } from "react-dom";

import { useModal } from "context";

import { StyledModalWrapper, StyledModalContainer } from "./styled";
import { ModalWrapperProps } from "./types";
import { appearanceAnimationMap, appearanceAnimationVariants } from "theme";

const ModalWrapper: React.FC<ModalWrapperProps> = ({ children, hideModalHandler }) => {
  const modalRoot = document.getElementById("modal");

  const { props } = useModal();

  const onParentContainerClick = (event: React.MouseEvent<HTMLDivElement>) => {
    return event.stopPropagation();
  };

  if (modalRoot) {
    return createPortal(
      <StyledModalWrapper
        key="modal"
        {...appearanceAnimationMap}
        variants={appearanceAnimationVariants}
        transition={{ duration: 0.5 }}
        onClick={hideModalHandler}
      >
        <StyledModalContainer onClick={onParentContainerClick} borderRadius={props?.borderRadius}>
          {children}
        </StyledModalContainer>
      </StyledModalWrapper>,

      modalRoot,
    );
  }
  return null;
};

export default ModalWrapper;
