import React from "react";
import { Svg } from "components";
import { SvgProps } from "../types";
import { useThemeContext } from "context";

const Icon: React.FC<SvgProps> = ({ color = "primary", ...props }) => {
  const { theme } = useThemeContext();

  return (
    <Svg viewBox="0 0 18 16" {...props} color="transparent">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.11497 0.26874C3.18702 0.200878 3.28226 0.163086 3.38123 0.163086H17.6663C18.0104 0.163086 18.1845 0.577362 17.9438 0.823133L15.2338 3.58995C15.1608 3.66452 15.0608 3.70655 14.9564 3.70655H0.443856C0.0922711 3.70655 -0.0783284 3.27662 0.177599 3.03555L3.11497 0.26874ZM14.9405 5.55943C14.8684 5.49157 14.7732 5.45378 14.6742 5.45378H0.389102C0.0450816 5.45378 -0.129043 5.86806 0.111678 6.11383L2.82164 8.88064C2.89468 8.95522 2.99468 8.99724 3.09906 8.99724H17.6116C17.9632 8.99724 18.1338 8.56732 17.8778 8.32625L14.9405 5.55943ZM3.38123 10.7449C3.28226 10.7449 3.18702 10.7827 3.11497 10.8505L0.177599 13.6174C-0.0783284 13.8584 0.0922711 14.2884 0.443856 14.2884H14.9564C15.0608 14.2884 15.1608 14.2463 15.2338 14.1718L17.9438 11.4049C18.1845 11.1592 18.0104 10.7449 17.6663 10.7449H3.38123Z"
        fill={theme.colors[color]}
      />
    </Svg>
  );
};

export default Icon;
