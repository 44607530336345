import styled from "styled-components";
import { Button, Heading } from "components";

export const StyledTab = styled(Button<"button">).attrs({ scale: "md" })<{ isActive?: boolean }>`
  min-width: 120px;
  padding: 10px 5px;
  background-color: ${({ theme, isActive }) => (isActive ? theme.colors.neutral600 : theme.colors.neutral800)};
  border: 2px solid ${({ theme, isActive }) => (isActive ? theme.colors.neutral600 : theme.colors.neutral700)};
  ${Heading} {
    color: ${({ theme }) => theme.colors.neutral500};
    white-space: nowrap;
  }

  &:not([disabled]):hover {
    background-color: ${({ theme }) => theme.colors.neutral600};
    border: 2px solid ${({ theme }) => theme.colors.neutral600};

    ${Heading} {
      color: ${({ theme }) => theme.colors.neutral0};
    }

    &:not([disabled]):active {
      background-color: ${({ theme }) => theme.colors.neutral700};
      border: 2px solid ${({ theme }) => theme.colors.neutral700};
    }
  }

  &:focus {
    outline: unset;
  }
`;
