import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

import { useModal, useTranslation } from "context";
import { useMatchBreakpoints } from "hooks";

import { Heading, Text, Link, Box } from "components";
import {
  StyledLanguageItemWrapper,
  StyledLanguageSelectorContainer,
  StyledLanguageSelectorInnerContainer,
} from "./styled";
import CloseModalWrapper from "../close-modal-wrapper";

import { languages } from "configs";
import { removeLangParamFromPathname } from "utils";

import { Language } from "context/language-context/types";

const LanguageSelectorModal: React.FC = () => {
  const { closeModal, updateModalProps } = useModal();
  const { currentLanguage, getLocalizedPath, t } = useTranslation();
  const { pathname } = useLocation();
  const { isMobile } = useMatchBreakpoints();

  const getLanguageLinkPath = (language: Language) => {
    const currentPath = removeLangParamFromPathname(pathname, currentLanguage.locale);
    return getLocalizedPath(currentPath, language, true);
  };

  const handleCloseModal = () => {
    closeModal();
  };

  useEffect(() => {
    if (isMobile) {
      updateModalProps({ borderRadius: "0px" });
    } else {
      updateModalProps({ borderRadius: "16px" });
    }
  }, [isMobile]);

  return (
    <StyledLanguageSelectorContainer>
      <CloseModalWrapper closeModalHandler={handleCloseModal} mb="24px">
        {isMobile && (
          <Text textScale="bodyL" $fontWeight="medium">
            {t("Choose a language")}
          </Text>
        )}
      </CloseModalWrapper>

      {!isMobile && (
        <Heading scale="h4" as="h4" textAlign="center">
          {t("Choose a language")}
        </Heading>
      )}

      <StyledLanguageSelectorInnerContainer>
        {Object.values(languages).map(language => (
          <Box width="100%" maxWidth={{ _: "calc(50% - 8px)", tablet: "168px", laptop: "154px" }} key={language.code}>
            <Link
              href={getLanguageLinkPath(language)}
              reloadDocument={currentLanguage.code !== language.code}
              width="100%"
              display="inline-block"
            >
              <StyledLanguageItemWrapper isSelectedItem={currentLanguage.code === language.code}>
                <Text
                  textScale="bodyS"
                  color="neutral200"
                  $fontWeight="medium"
                  whiteSpace="nowrap"
                  textTransform="capitalize"
                >
                  {language.language}
                </Text>

                <Text textScale="captionS" color="neutral400" translate="no">
                  {language.translate}
                </Text>
              </StyledLanguageItemWrapper>
            </Link>
          </Box>
        ))}
      </StyledLanguageSelectorInnerContainer>
    </StyledLanguageSelectorContainer>
  );
};

export default LanguageSelectorModal;
