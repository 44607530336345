import { useModal } from "context";

import useMatchBreakpoints from "./use-match-breakpoints/use-match-breakpoints";

export const useHandleLanguageSelectorClick = () => {
  const { isMobile } = useMatchBreakpoints();

  const { openModal } = useModal();

  const handleLanguageSelectorClick = () => {
    openModal({
      modalName: "languageSelectorModal",
      props: { borderRadius: isMobile ? "0px" : "16px" }, // border radius for StyledModalContainer
    });
  };

  return { handleLanguageSelectorClick };
};
