import styled, { css } from "styled-components";

import { Box } from "components/layout";
import { BurgerMenuProps } from "./types";

const pseudoStyles = css`
  content: "";
  position: absolute;
  width: 16px;
  height: 1px;
  left: 4px;
  background-color: ${({ theme }) => theme.colors.neutral200};
  transition: transform 0.3s ease;
`;

export const StyledMenu = styled.div<{ isOpen: BurgerMenuProps["isOpen"] }>`
  display: flex;
  width: 24px;
  height: 24px;
  position: relative;
  cursor: pointer;
  align-items: center;
  margin-inline-start: 32px;

  &::after {
    top: ${({ isOpen }) => (isOpen ? "11px" : "4px")};
    transform: ${({ isOpen }) => (isOpen ? `rotate(45deg)` : `rotate(0deg)`)};
    ${pseudoStyles}
  }

  &::before {
    bottom: ${({ isOpen }) => (isOpen ? "12px" : "9px")};
    transform: ${({ isOpen }) => (isOpen ? `rotate(-45deg)` : `rotate(0deg)`)};
    ${pseudoStyles}
  }
`;

export const StyledSpan = styled(Box)`
  left: 4px;
  position: absolute;
  height: 1px;
  transition: width 0.3s ease;
`;
