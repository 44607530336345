import styled from "styled-components";

import { Box, Svg, Text } from "components";

export const StyledDropdown = styled(Box)`
  ${Text} {
    color: ${({ theme }) => theme.colors.neutral500};
  }
  ${Svg} {
    path {
      stroke: ${({ theme }) => theme.colors.neutral500};
    }
  }
`;
