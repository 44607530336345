import React from "react";
import { Svg } from "components";
import { SvgProps } from "../types";
import { useThemeContext } from "context";

const Icon: React.FC<SvgProps> = ({ color = "orange", ...props }) => {
  const { theme } = useThemeContext();

  return (
    <Svg viewBox="0 0 24 24" color="transparent" {...props}>
      <path
        d="M11.9998 21.1669C7.02721 21.1669 2.99609 17.1358 2.99609 12.1632C2.99609 7.19054 7.02721 3.15942 11.9998 3.15942C16.9725 3.15942 21.0036 7.19054 21.0036 12.1632"
        stroke={theme.colors[color]}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.51172 9.16207H20.3867"
        stroke={theme.colors[color]}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.51172 15.1645H12.0013"
        stroke={theme.colors[color]}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.0029 12.1632C16.0104 9.36826 15.2613 6.62342 13.8349 4.21986C13.4575 3.56396 12.7584 3.15967 12.0017 3.15967C11.2449 3.15967 10.5458 3.56396 10.1684 4.21986C7.2772 9.12113 7.2772 15.2062 10.1684 20.1075C10.5475 20.762 11.2458 21.1658 12.0022 21.1679"
        stroke={theme.colors[color]}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle
        cx="18.0017"
        cy="18.1657"
        r="4.00167"
        stroke={theme.colors[color]}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.2478 17.5444L17.6912 19.1011L16.7578 18.1667"
        stroke={theme.colors[color]}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
};

export default Icon;
