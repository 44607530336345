import styled from "styled-components";
import { border, space, layout, typography } from "styled-system";
import { TextareaProps } from "./types";
import { getBorderStyles } from "../";

export const Textarea = styled.textarea<TextareaProps>`
  background-color: transparent;
  border-radius: ${({ theme }) => theme.radii.medium};
  color: ${({ theme }) => theme.colors.neutral200};
  font-family: ${({ theme }) => theme.fonts.thicccboi};
  display: block;
  resize: none;
  font-size: 16px;
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  outline: 0;
  padding: 16px 24px;
  width: 100%;

  &::placeholder {
    color: ${({ theme }) => theme.colors.neutral500};
  }

  &:disabled {
    background-color: ${({ theme }) => theme.colors.neutral400};
    border: 1px solid ${({ theme }) => theme.colors.neutral600};
    color: ${({ theme }) => theme.colors.neutral900};
    cursor: not-allowed;
  }

  &:focus:not(:disabled) {
    border: 1px solid ${({ theme }) => theme.colors.neutral600};
  }

  &:hover:not(:disabled) {
    border: 1px solid ${({ theme }) => theme.colors.neutral600};
  }

  ${getBorderStyles}

  ${typography}
  ${space}
  ${layout}
  ${border}
`;
