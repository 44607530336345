// Components
import { Column, Text, Flex, Image } from "components";

import { useTranslation } from "context";
// Types
import { StepInfo } from "../../types";
// Constants
import { DIMENSIONS } from "configs";

const ActiveStepItem: React.FC<StepInfo> = ({ description, image }) => {
  const { t } = useTranslation();

  return (
    <Column alignItems="center" width="100%">
      <Text mt="28px" textScale="bodyM" color="neutral200" textAlign="center">
        {t(description)}
      </Text>

      <Flex mt={{ _: "32px", laptop: "36px" }} width="100%" justifyContent="center">
        <Image src={image} width={DIMENSIONS.BANNER_IMAGE_WIDTH} aspectRatio={1.262} />
      </Flex>
    </Column>
  );
};

export default ActiveStepItem;
