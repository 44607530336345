import { Column, Heading, Text, Flex, FlexGap, StyledIconBox } from "components";
import { StyledCardWrapper, StyledCardImage } from "./styled";

import { DIMENSIONS } from "configs";
import { useTranslation } from "context";
// Types
import { BannerCardProps } from "./types";

const BannerCard: React.FC<BannerCardProps> = ({
  title,
  description,
  icon: Icon,
  color,
  backgroundImg,
  position,
  cardImg,
  imgWidth,
  imgHeight,
  reverse = false,
  paddingImg = "40px",
  imageWrapperProps = {},
}) => {
  const { t } = useTranslation();

  return (
    <StyledCardWrapper backgroundPosition={position} backgroundImg={backgroundImg}>
      <FlexGap
        width="100%"
        gap="30px"
        flexDirection={{ _: "column", laptop: reverse ? "row-reverse" : "row" }}
        alignItems="center"
      >
        <Column
          p={{ _: "0px", laptop: "40px" }}
          maxWidth={{ _: DIMENSIONS.TABLET_CONTENT_WRAPPER_WIDTH, laptop: "50%" }}
          width="100%"
          alignItems="start"
        >
          <StyledIconBox color={color}>
            <Icon color={color} />
          </StyledIconBox>

          <Heading
            mt={{ _: "32px", laptop: "24px" }}
            scale={{ _: "h5", laptop: "h4" }}
            as="h2"
            color="neutral200"
            $fontWeight="semiBold"
          >
            {t(title)}
          </Heading>

          <Text my="24px" textScale={{ _: "bodyS", laptop: "bodyM" }} color="neutral400">
            {t(description)}
          </Text>
        </Column>
        <Flex
          py={{ _: "0px", laptop: paddingImg }}
          flex="1"
          height="100%"
          justifyContent="center"
          alignItems="center"
          {...imageWrapperProps}
        >
          <StyledCardImage cardImg={cardImg} width={imgWidth} height={imgHeight} />
        </Flex>
      </FlexGap>
    </StyledCardWrapper>
  );
};

export default BannerCard;
