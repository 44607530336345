import React from "react";

import { FooterWrapper, StyledContainer } from "./styled";
import { DesktopGrid, MobileGrid } from "./components";
import { Container } from "components";

import { useMatchBreakpoints } from "hooks";

const Footer: React.FC = () => {
  const { isDesktop } = useMatchBreakpoints();

  return (
    <StyledContainer>
      <Container>
        <FooterWrapper>{isDesktop ? <DesktopGrid /> : <MobileGrid />}</FooterWrapper>
      </Container>
    </StyledContainer>
  );
};

export default Footer;
