import React from "react";
import { Svg } from "components";

import { SvgProps } from "../types";
import { useThemeContext } from "context";

const Icon: React.FC<SvgProps> = ({ ...props }) => {
  const { theme } = useThemeContext();

  return (
    <Svg viewBox="0 0 120 39" {...props} color="transparent">
      <path
        d="M66.8428 25.0876V28.6168H50.6094V25.8178L60.7855 13.4778H50.8032V9.94867H66.4309V12.7477L56.279 25.0876H66.8428ZM83.7304 25.1363V28.5924H69.3626V9.94867H83.3912V13.4048H73.6269V17.4451H82.2524V20.8039H73.6269V25.1119H83.7304V25.1363ZM98.607 28.6168L95.0211 23.4082H94.803H91.0717V28.6168H86.7832V9.94867H94.8272C96.4748 9.94867 97.9043 10.2164 99.1158 10.7762C100.327 11.336 101.248 12.1149 101.926 13.1128C102.581 14.135 102.92 15.3276 102.92 16.7149C102.92 18.1023 102.581 19.2949 101.926 20.2928C101.272 21.2907 100.327 22.0696 99.0915 22.605L103.259 28.5924H98.607V28.6168ZM97.5167 14.3054C96.8382 13.7456 95.8691 13.4778 94.5607 13.4778H91.0475V19.9764H94.5607C95.8449 19.9764 96.8382 19.6843 97.5167 19.1245C98.1951 18.5647 98.5343 17.7615 98.5343 16.7149C98.51 15.6684 98.1708 14.8652 97.5167 14.3054ZM109.752 13.4778H103.816V9.94867H120.001V13.4778H114.065V28.6168H109.752V13.4778Z"
        fill={theme.colors.neutral200}
      />
      <path
        d="M36.5555 27.7892L31.5885 22.7997V6.83321H27.6392L30.1348 4.32628C30.6678 3.79082 31.3947 3.49875 32.0973 3.49875C32.8 3.49875 33.5268 3.76648 34.0599 4.32628C35.1502 5.42154 35.1502 7.1983 34.0599 8.29356L33.3088 9.04807V14.0133L36.5312 10.7761C38.9784 8.3179 38.9784 4.30194 36.5312 1.84369C34.0841 -0.614563 30.0863 -0.614563 27.6392 1.84369L22.6965 6.83321H6.80228V10.8005L4.3067 8.29356C3.2164 7.1983 3.2164 5.42154 4.3067 4.32628C4.83974 3.79082 5.56661 3.49875 6.26925 3.49875C6.97189 3.49875 7.69875 3.76648 8.23179 4.32628L8.98289 5.08079H13.9256L10.7274 1.84369C8.28025 -0.614563 4.28247 -0.614563 1.83534 1.84369C-0.611781 4.30194 -0.611781 8.3179 1.83534 10.7761L6.80228 15.7657V31.7321H10.7516L8.25602 34.2391C7.16572 35.3343 5.397 35.3343 4.3067 34.2391C3.2164 33.1438 3.2164 31.367 4.3067 30.2718L5.0578 29.5173V24.5521L1.83534 27.7892C-0.611781 30.2474 -0.611781 34.2634 1.83534 36.7217C3.02256 37.9143 4.59745 38.5714 6.29347 38.5714C7.96527 38.5714 9.54016 37.9143 10.7516 36.7217L15.7185 31.7321H31.6127V27.7649L34.1083 30.2718C35.1986 31.367 35.1986 33.1438 34.1083 34.2391C33.018 35.3343 31.2493 35.3343 30.159 34.2391L29.4079 33.4846H24.4652L27.6877 36.7217C28.8749 37.9143 30.4498 38.5714 32.1458 38.5714C33.8176 38.5714 35.3925 37.9143 36.6039 36.7217C39.0026 34.2634 39.0026 30.2474 36.5555 27.7892ZM28.0995 28.203H19.2075L24.3925 22.9944L21.8969 20.5361L14.2406 28.2273H10.2913V19.2705L15.4763 24.4791L17.9476 21.9965L10.2913 14.3053V10.338H19.1833L14.0225 15.5466L16.4939 18.0292L24.1502 10.338H28.0995V19.2705L22.9145 14.0619L20.419 16.5689L28.0995 24.26V28.203Z"
        fill={theme.colors.primary}
      />
    </Svg>
  );
};

export default Icon;
