import styled from "styled-components";
import { FlexGap } from "components";
import { DIMENSIONS } from "configs";

export const StyledTabPanelWrapper = styled(FlexGap)<{ zIndex?: number }>`
  width: 100%;
  position: absolute;
  z-index: ${({ theme }) => theme.zIndices.tabPanel};
  max-width: ${DIMENSIONS.TABLET_CONTENT_WRAPPER_WIDTH};
  overflow-x: auto;
  &::-webkit-scrollbar {
    display: none;
  }
`;
