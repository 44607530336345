import styled, { css } from "styled-components";
import { layout, space } from "styled-system";

import { Box, Text } from "components";

import { InputIconProps, StyledInputGroupProps } from "./types";
import { Scales, scales as inputScales } from "components/inputs/input/types";

const getPadding = (scale: Scales, hasIcon: boolean) => {
  if (!hasIcon) {
    return 24;
  }

  switch (scale) {
    case inputScales.MD:
      return 48;
  }
};

export const StyledInputGroup = styled(Box)<StyledInputGroupProps>`
  input {
    padding-inline-start: ${({ hasStartIcon, scale }) => getPadding(scale, hasStartIcon)}px;
    padding-inline-end: ${({ hasEndIcon, scale }) => getPadding(scale, hasEndIcon)}px;
  }
  position: relative;
  ${layout}
  ${space}
`;

export const InputIcon = styled.div<InputIconProps>`
  align-items: center;
  display: flex;
  height: 100%;
  position: absolute;
  top: 0;

  ${({ isEndIcon }) =>
    isEndIcon
      ? css`
          inset-inline-end: 16px;
        `
      : css`
          inset-inline-start: 16px;
        `}
`;

export const InputError = styled(Text)<StyledInputGroupProps>`
  color: ${({ theme }) => theme.colors.red};
  white-space: pre-wrap;
`;

export const AnimatedTitleWrapper = styled.div<StyledInputGroupProps>`
  top: ${({ isFocused }) => (isFocused ? "-10px" : "15px")};
  inset-inline-start: ${({ hasStartIcon, scale }) => getPadding(scale, hasStartIcon) - 10}px;
  background-color: ${({ theme }) => theme.colors.neutral1000};

  position: absolute;
  padding-inline-end: 10px;
  padding-inline-start: 10px;
  z-index: 10;
  transition: all 0.3s ease;

  ${Text} {
    font-size: ${({ isFocused }) => (isFocused ? "12px" : "16px")};
    line-height: ${({ isFocused }) => (isFocused ? "20px" : "26px")};
    transition: all 0.3s ease;
  }
`;
