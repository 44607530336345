import axios from "axios";

const timeout = 15_000;

export function getInstance(baseURL = process.env.REACT_APP_API_URL) {
  const instance = axios.create({
    baseURL,
    timeout,
  });

  return instance;
}
