export const breakpointMap = {
  mobileS: 320,
  mobileM: 375,
  mobileL: 425,
  tablet: 768,
  laptop: 1024,
  laptopL: 1440,
} as const;

export const breakpointsArray = ["320px", "375px", "425px", "768px", "1024px", "1440px"];

export const breakpoints = Object.assign(breakpointsArray, {
  mobileS: breakpointsArray[0],
  mobileM: breakpointsArray[1],
  mobileL: breakpointsArray[2],
  tablet: breakpointsArray[3],
  laptop: breakpointsArray[4],
  laptopL: breakpointsArray[5],
});

export const mediaQueries = {
  mobileS: `@media screen and (min-width: ${breakpointsArray[0]})`,
  mobileM: `@media screen and (min-width: ${breakpointsArray[1]})`,
  mobileL: `@media screen and (min-width: ${breakpointsArray[2]})`,
  tablet: `@media screen and (min-width: ${breakpointsArray[3]})`,
  laptop: `@media screen and (min-width: ${breakpointsArray[4]})`,
  laptopL: `@media screen and (min-width: ${breakpointsArray[5]})`,
} as const;

export const shadows = {
  tooltip: "0px 40px 64px -12px rgba(0, 0, 0, 0.02), 0px 32px 48px -8px rgba(0, 0, 0, 0.08)",
  dropdown: "0px 32px 48px -8px rgba(0, 0, 0, 0.08)",
} as const;

export const gradients = {
  navigationMenu: "linear-gradient(0deg, #94a0b8 40%, #94a0b8 25%, #94a0b8 0%)",
  languageItem: "rgba(148, 160, 184, 0.8)",
} as const;

export const radii = {
  small: "4px",
  semiSmall: "6px",
  medium: "8px",
  semiMedium: "16px",
  semiLarge: "24px",
  large: "36px",
  circle: "50%",
} as const;

export const zIndices = {
  header: 11,
  modal: 100,
  tooltip: 101,
  tabPanel: 9,
} as const;

export const fontWeight = {
  bold: 700,
  semiBold: 600,
  medium: 500,
} as const;

export const fonts = {
  thicccboi: "THICCCBOI, sans-serif",
} as const;

export default {
  siteWidth: 1200,
  breakpoints,
  mediaQueries,
  shadows,
  radii,
  zIndices,
  fonts,
  fontWeight,
  gradients,
};
