import { createGlobalStyle } from "styled-components";

import THICCCBOIMedium from "assets/fonts/THICCCBOI-Medium.woff2";
import THICCCBOISemiBold from "assets/fonts/THICCCBOI-SemiBold.woff2";
import THICCCBOIBold from "assets/fonts/THICCCBOI-Bold.woff2";

const GlobalStyle = createGlobalStyle`
@font-face {
  font-family: "THICCCBOI";
  src: url(${THICCCBOIMedium}) format("woff2");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "THICCCBOI";
  src: url(${THICCCBOISemiBold}) format("woff2");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "THICCCBOI";
  src: url(${THICCCBOIBold}) format("woff2");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

html, body, div, span, applet, object, iframe,
  h1, h2, h3, h4, h5, h6, p, blockquote, pre,
  a, abbr, acronym, address, big, cite, code,
  del, dfn, em, img, ins, kbd, q, s, samp,
  small, strike, strong, sub, sup, tt, var,
  b, u, i, center,
  dl, dt, dd, ol, ul, li,
  fieldset, form, label, legend,
  table, caption, tbody, tfoot, thead, tr, th, td,
  article, aside, canvas, details, embed, 
  figure, figcaption, footer, header, hgroup, 
  menu, nav, output, ruby, section, summary,
  time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    vertical-align: baseline;
  }
  /* HTML5 display-role reset for older browsers */
  article, aside, details, figcaption, figure, 
  footer, header, hgroup, menu, nav, section {
    display: block;
  }
  ol,
  ul {
    list-style: disc;
    list-style-position: inside;
  }
  blockquote,
  q {
    quotes: none;
  }
  blockquote:before,
  blockquote:after,
  q:before,
  q:after {
    content: "";
    content: none;
  }
  table {
    border-collapse: collapse;
    border-spacing: 0;
  }
  a {
    color: inherit;
    text-decoration: none;
  }
  [role="button"] {
    cursor: pointer;
  }
  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }
  * {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  /* Number */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type=number] {
    -moz-appearance: textfield;
  }

  /* Scrollbar */
  ::-webkit-scrollbar {
    width: 8px;
  }
  ::-webkit-scrollbar-thumb {
    background-image: ${({ theme }) => `linear-gradient(to bottom, ${theme.colors.purple}, ${theme.colors.pink})`};
    border-radius: ${({ theme }) => theme.radii.medium};
  }

  html {
    direction: ltr !important;
  }

  body {
    line-height: 1;
    font-size: 16px;
    overflow-x: hidden;
    min-width: 100vw;
    min-height: 100vh;
    margin: 0;
    font-family: ${({ theme }) => theme.fonts.thicccboi};
    background-color: ${({ theme }) => theme.colors.neutral1000};

    img {
      height: auto;
      max-width: 100%;
    }
  }
  #root {
    height: 100%;
    min-height: 100vh;
  }

  .grecaptcha-badge {
    display: none !important;
  }
`;

export default GlobalStyle;
