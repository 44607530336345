import React from "react";

import { Flex, Text } from "components";
import { DropdownSelectWrapper } from "./styled";
import { DropdownArrowIcon } from "components/svg";

import { useTooltip } from "hooks";

import { useThemeContext, useTranslation } from "context";
import { getTooltipStyles } from "./theme";

import { DropdownComponentProps, DropdownSelectProps } from "./types";

const DropdownSelect = <T extends DropdownComponentProps>({
  title,
  dropdownComponent,
  dropdownProps,
}: DropdownSelectProps<T>) => {
  const { t } = useTranslation();
  const { theme } = useThemeContext();

  const DropdownComponent = dropdownComponent;

  const { targetRef, tooltip, setVisible } = useTooltip(
    <DropdownComponent {...dropdownProps} onClick={onDropdownMenuClick} />,
    {
      placement: "bottom",
      customStyles: getTooltipStyles(theme),
      tooltipOffset: [0, 0],
    },
  );

  function onDropdownMenuClick() {
    setVisible(prev => !prev);
    dropdownProps.onClick?.();
  }

  return (
    <DropdownSelectWrapper ref={targetRef}>
      <Flex>
        {typeof title === "string" ? <Text textScale="bodyM">{t(title)}</Text> : React.isValidElement(title) && title}

        <DropdownArrowIcon />
      </Flex>
      {tooltip}
    </DropdownSelectWrapper>
  );
};

export default DropdownSelect;
