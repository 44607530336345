import { IncomeCoinsIcon, SecurityIcon, ShieldIcon, StackOfCoinsIcon, StrongBoxIcon, SwapIcon } from "components/svg";

import { NavigationMenuItemType } from "./types";

import { ROUTES } from "navigation/routes";

export const NAVIGATION_MENU_ITEMS: NavigationMenuItemType[] = [
  { title: "Digital Storage", icon: StrongBoxIcon, color: "primary", path: ROUTES.digitalStorage },
  { title: "Buy Crypto", icon: IncomeCoinsIcon, color: "green", path: ROUTES.buyCrypto },
  { title: "Staking", icon: StackOfCoinsIcon, color: "pink", path: ROUTES.staking },
  { title: "Swap Crypto", icon: SwapIcon, color: "purple", path: ROUTES.swap },
  { title: "Escrow Account", icon: ShieldIcon, color: "orange", path: ROUTES.escrow },
  { title: "Security Features", icon: SecurityIcon, color: "primary", path: ROUTES.security },
];
