import React from "react";

import { Heading, Column, Text, Box, TagsWrapper } from "components";
import { StyledCardWrapper, StyledCardImg } from "./styled";

import { useTranslation } from "context";

import { formatDate } from "utils";
import { ROUTES } from "navigation/routes";

import { NewsCardItemProps } from "./types";

const NewsCard: React.FC<NewsCardItemProps> = ({ post: { title, created, featured_image, tags, slug } }) => {
  const { getLocalizedPath } = useTranslation();

  return (
    <StyledCardWrapper as="a" href={getLocalizedPath(`/${ROUTES.blogs}/${slug}`)}>
      <Column flex="1">
        <Box maxHeight="264px" minHeight="100px" position="relative" $backgroundColor="neutral300">
          <StyledCardImg backgroundImg={featured_image ?? "/images/news/news.webp"} />

          <Box position="absolute" left="40px" right="40px" top="34px">
            <TagsWrapper tags={tags} />
          </Box>
        </Box>

        <Column flex="1 1 auto" p={{ _: "24px 28px", laptop: "32px 40px" }} $backgroundColor="neutral900" zIndex={1}>
          {created && (
            <Text textScale="captionS" color="neutral400" textTransform="uppercase">
              {formatDate(created)}
            </Text>
          )}

          <Heading mt="16px" scale={{ _: "h5", laptop: "h4" }} as="h5" color="neutral200" $fontWeight="semiBold">
            {title}
          </Heading>
        </Column>
      </Column>
    </StyledCardWrapper>
  );
};

export default NewsCard;
