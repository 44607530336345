import React, { PropsWithChildren } from "react";
import { Column, Container, ContentBlockWrapper } from "components";

export const CardsSectionWrapper: React.FC<PropsWithChildren> = ({ children }) => {
  return (
    <ContentBlockWrapper>
      <Container width="100%">
        <Column height="100%" justifyContent="center" alignItems="center">
          {children}
        </Column>
      </Container>
    </ContentBlockWrapper>
  );
};
