import React, { Suspense } from "react";

import { StyleSheetManager, ThemeProvider } from "styled-components";
import { HelmetProvider } from "react-helmet-async";
import { createBrowserRouter, RouterProvider, ScrollRestoration } from "react-router-dom";
// Styles
import { GlobalStyle } from "styles";
// Context
import { LanguageContextProvider, ModalContextProvider, ThemeContextProvider, useThemeContext } from "context";
// Components
import { ErrorBoundary, Loading, ErrorBoundaryFallback, Modal } from "components";
import Navigation from "navigation";
// Hooks
import { useCheckRouter } from "hooks";

import { shouldForwardProp } from "utils";

const ThemedApp: React.FC = () => {
  const { theme } = useThemeContext();

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />

      <StyleSheetManager shouldForwardProp={shouldForwardProp}>
        <Suspense fallback={<Loading isAnimate={false} />}>
          <ErrorBoundary fallbackComponent={ErrorBoundaryFallback}>
            <ModalContextProvider>
              <LanguageContextProvider fallback={<Loading isAnimate={false} />}>
                <Modal />
                <Navigation />
              </LanguageContextProvider>
            </ModalContextProvider>
          </ErrorBoundary>
        </Suspense>
      </StyleSheetManager>
    </ThemeProvider>
  );
};

const Root: React.FC = () => {
  return (
    <>
      <HelmetProvider>
        <ThemeContextProvider>
          <ThemedApp />
        </ThemeContextProvider>
      </HelmetProvider>

      <ScrollRestoration />
    </>
  );
};

const router = createBrowserRouter([{ path: "*", Component: Root }]);

const App: React.FC = () => {
  useCheckRouter(router);

  return <RouterProvider router={router} />;
};

export default App;
