import React from "react";

import { Button, Box, InputGroup } from "components";
import { InputIcon, StyledInput } from "./styled";
import { ArrowIcon } from "components/svg";

import { useValidationSchema } from "./hooks";
import { useForm } from "hooks";

import { useTranslation } from "context";

import { InputProps } from "../input/types";
import { InputEmailProps } from "./types";

export const InputEmail = <E extends React.ElementType = "input">({
  isLoading = false,
  onSendClick,
  ...props
}: InputProps<E> & InputEmailProps) => {
  const { t } = useTranslation();

  const { validationSchema, initialValues } = useValidationSchema();

  const { errors, touched, isValid, fieldProps, handleSubmit, resetForm } = useForm({
    initialValues,
    validationSchema,
    onSubmit(values) {
      onSendClick(values);
      resetForm();
    },
  });

  return (
    <Box position="relative">
      <form onSubmit={handleSubmit}>
        <InputGroup error={errors.email} isTouched={touched.email}>
          <>
            <StyledInput {...fieldProps("email")} placeholder={t("Enter your email")} {...props} />

            <InputIcon>
              <Button
                isLoading={isLoading}
                disabled={!isValid}
                scale="sm"
                type="submit"
                title="Subscription email Button"
              >
                <ArrowIcon width="24px" rotate="-45deg" />
              </Button>
            </InputIcon>
          </>
        </InputGroup>
      </form>
    </Box>
  );
};
