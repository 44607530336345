import React from "react";
// Components
import { Column, FlexGap, Heading, Text } from "components";
import { StyledValueCardWrapper, StyledValueCardIconBox } from "./styled";
// Context
import { useTranslation } from "context";
// Types
import { InfoCardProps } from "../";

const ValueCard: React.FC<InfoCardProps> = ({
  title,
  description,
  icon: Icon,
  color,
  backgroundImg,
  position,
  backgroundSize,
}) => {
  const { t } = useTranslation();

  return (
    <StyledValueCardWrapper
      p={{ _: "14px 18px", laptop: "27px 35px" }}
      backgroundPosition={position}
      backgroundImg={backgroundImg}
      backgroundSize={backgroundSize}
    >
      <FlexGap gap={{ _: "15px", laptop: "28px" }} alignItems="center">
        <StyledValueCardIconBox color={color}>
          <Icon width={{ _: "12px", laptop: "24px" }} color={color} />
        </StyledValueCardIconBox>

        <Column>
          <Heading scale="h5" as="h5" color="neutral200">
            {t(title)}
          </Heading>

          <Text mt={{ _: "4px", laptop: "8px" }} textScale="bodyM" color="neutral400">
            {t(description)}
          </Text>
        </Column>
      </FlexGap>
    </StyledValueCardWrapper>
  );
};

export default ValueCard;
