const BANNER_IMAGE_WIDTH = "820px";
const BANNER_IMAGE_WIDTH_SM = "650px";

const TABLET_CONTENT_WRAPPER_WIDTH = "650px";

export const DIMENSIONS = {
  BANNER_IMAGE_WIDTH,
  BANNER_IMAGE_WIDTH_SM,
  TABLET_CONTENT_WRAPPER_WIDTH,
};
