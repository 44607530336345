export type ContextType = {
  modalName: null | keyof typeof ModalNames;
  onModalClose?: () => void;
  clickOutsideHandler?: () => void;
  props?: ModalProps;
};

export type ModalProps = {
  description?: string;
  title?: string;
  buttonText?: string;
  borderRadius?: string;
  onButtonClick?: () => void;
};

export enum ModalNames {
  testModal = "testModal",
  actionModal = "actionModal",
  languageSelectorModal = "languageSelectorModal",
}

export interface ModalContextValue extends ContextType {
  openModal: (modalProps: ContextType) => void;
  closeModal: () => void;
  onOutsideClick: () => void;
  updateModalProps: (modalProps: ModalProps) => void;
}
