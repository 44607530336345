import React from "react";

import { DropdownSelect, NavigationMenu } from "components";

import { NavigationMenuProps } from "../navigation-menu/types";

export const NavigationDropdown: React.FC = () => {
  return (
    <DropdownSelect<NavigationMenuProps>
      title="Services"
      dropdownComponent={NavigationMenu}
      dropdownProps={{
        onClick: () => {},
      }}
    />
  );
};
