import React, { useState } from "react";
// Components
import { Text, FlexGap, Heading, AccordionContent, Flex } from "components";
import { DropdownArrowIcon } from "components/svg";
import { StyledItemWrapper, StyledArrowIconBox } from "./styled";
// Context
import { useTranslation } from "context";
// Types
import { AccordionItemProps } from "./types";

const AccordionItem: React.FC<AccordionItemProps> = ({ question, answerText }) => {
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation();

  const setAnswerOpen = () => {
    setIsOpen(prev => !prev);
  };

  return (
    <StyledItemWrapper isOpen={isOpen}>
      <FlexGap width="100%" justifyContent="space-between" gap="10px">
        <Heading scale={{ _: "h5", laptop: "h4" }} as="h5" color="neutral200" textAlign="left">
          {t(question)}
        </Heading>

        <Flex width="40px" alignSelf="center">
          <StyledArrowIconBox color="neutral800" onClick={setAnswerOpen}>
            <DropdownArrowIcon width="24px" rotate={isOpen ? "180deg" : "0deg"} />
          </StyledArrowIconBox>
        </Flex>
      </FlexGap>
      <AccordionContent isOpen={isOpen}>
        <Text my="24px" textScale="bodyM" maxWidth="760px" color="neutral400" textAlign="left">
          {t(answerText)}
        </Text>
      </AccordionContent>
    </StyledItemWrapper>
  );
};

export default AccordionItem;
