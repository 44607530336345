import React from "react";
import { Svg } from "components";
import { SvgProps } from "../types";
import { useThemeContext } from "context";

const Icon: React.FC<SvgProps> = ({ color = "primary", ...props }) => {
  const { theme } = useThemeContext();
  return (
    <Svg viewBox="0 0 24 24" {...props} color="transparent">
      <path
        d="M12 4.16406V6.60306"
        stroke={theme.colors[color]}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21 13.1631H19"
        stroke={theme.colors[color]}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3 13.1631H5"
        stroke={theme.colors[color]}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.3583 6.80322L13.4023 11.7592"
        stroke={theme.colors[color]}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.9805 11.1631C13.0855 11.1631 13.9805 12.0581 13.9805 13.1631C13.9805 14.2681 13.0855 15.1631 11.9805 15.1631C10.8755 15.1631 9.98047 14.2681 9.98047 13.1631C9.98047 12.0581 10.8755 11.1631 11.9805 11.1631"
        stroke={theme.colors[color]}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.64062 6.80322L7.76063 8.92322"
        stroke={theme.colors[color]}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.2402 17.4028L18.3603 19.5228L18.3643 19.5268C21.8793 16.0118 21.8793 10.3138 18.3643 6.79885C14.8493 3.28385 9.15125 3.28385 5.63625 6.79885C2.12125 10.3138 2.12125 16.0118 5.63625 19.5268L5.64025 19.5228L7.64025 17.5228"
        stroke={theme.colors[color]}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
};

export default Icon;
