import React from "react";
import { Svg } from "components";
import { SvgProps } from "../types";
import { useThemeContext } from "context";

const Icon: React.FC<SvgProps> = ({ color = "neutral0", ...props }) => {
  const { theme } = useThemeContext();
  return (
    <Svg viewBox="0 0 20 20" {...props} color="transparent">
      <path
        d="M16.6654 5.41667L7.4987 14.5833L3.33203 10.4167"
        stroke={theme.colors[color]}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
};

export default Icon;
