import styled from "styled-components";
import { Image } from "components";
import { DIMENSIONS } from "configs";

export const StyledWrapper = styled.div`
  display: flex;
  width: 100%;
  max-width: ${DIMENSIONS.TABLET_CONTENT_WRAPPER_WIDTH};
  justify-content: center;
  align-items: center;
  padding-top: 60px;

  ${({ theme }) => theme.mediaQueries.laptop} {
    max-width: 100%;
    height: 586px;
    padding-top: 0;
  }
`;

export const StyledLaptopImage = styled(Image)`
  position: absolute;
  left: 0;
`;
