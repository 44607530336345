import React from "react";

import { Flex, Image } from "components";
import { StyledLaptopImage, StyledWrapper } from "./styled";

import { useMatchBreakpoints } from "hooks";
import { ImageProps } from "../../types";

const ImagesContainer: React.FC<ImageProps> = ({ laptopProps, mobileProps }) => {
  const { isDesktop } = useMatchBreakpoints();

  return (
    <StyledWrapper>
      <Flex width="100%" position="relative" justifyContent="center" alignItems="center">
        {isDesktop ? <StyledLaptopImage {...laptopProps} /> : <Image {...mobileProps} />}
      </Flex>
    </StyledWrapper>
  );
};

export default ImagesContainer;
