import React from "react";
import { Svg } from "components";
import { SvgProps } from "../types";
import { useThemeContext } from "context";

const Icon: React.FC<SvgProps> = ({ color = "primary", ...props }) => {
  const { theme } = useThemeContext();

  return (
    <Svg viewBox="0 0 26 26" color="transparent" {...props}>
      <path
        d="M10.5117 19.0027V19.5149C10.5117 20.1754 10.7742 20.8088 11.2414 21.2756C11.7087 21.7425 12.3423 22.0045 13.0028 22.0039V22.0039C13.6633 22.0042 14.2969 21.742 14.7641 21.275C15.2313 20.808 15.4938 20.1745 15.4938 19.5139V19.0027"
        stroke={theme.colors[color]}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.9489 19.0026C20.0832 19.0026 21.0028 18.083 21.0028 16.9487V16.9487C21.0023 16.3725 20.7735 15.82 20.3665 15.4121L19.0019 14.0485V9.99884C19.0019 6.68375 16.3145 3.99634 12.9994 3.99634V3.99634C9.68434 3.99634 6.99693 6.68375 6.99693 9.99884V14.0485L5.63236 15.4121C5.22534 15.82 4.99655 16.3725 4.99609 16.9487V16.9487C4.99609 17.4934 5.21248 18.0159 5.59765 18.401C5.98283 18.7862 6.50523 19.0026 7.04995 19.0026H18.9489Z"
        stroke={theme.colors[color]}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
};

export default Icon;
