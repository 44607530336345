import { Text } from "components";
import { StyledItemWrapper } from "./styled";
// Context
import { useTranslation } from "context";

const PreferenceItem: React.FC<{ preference: string }> = ({ preference }) => {
  const { t } = useTranslation();

  return (
    <StyledItemWrapper>
      <Text textScale="bodyS" color="neutral400" textAlign="center">
        {t(preference)}
      </Text>
    </StyledItemWrapper>
  );
};

export default PreferenceItem;
