import styled from "styled-components";
import { Colors } from "theme/types";
import { scaleAnimation } from "theme";
import { border, layout, space, SpaceProps } from "styled-system";

export const StyledWrapper = styled.div`
  padding: 20px 0;
  width: 100%;
  overflow-y: scroll;
  ::-webkit-scrollbar {
    display: none;
  }

  background-color: ${({ theme }) => theme.colors.neutral900};
  border-radius: ${({ theme }) => theme.radii.semiMedium};

  ${({ theme }) => theme.mediaQueries.laptop} {
    padding: 30px 18px;
  }

  ${space}
  ${layout}
  ${border}
`;

export const StyledMenuItem = styled.div<{ color: keyof Colors; isActive?: boolean } & SpaceProps>`
  display: flex;
  cursor: ${({ isActive }) => (isActive ? "not-allowed" : "pointer")};
  width: 100%;
  align-items: center;
  padding: 8px 10px;
  border-radius: ${({ theme }) => theme.radii.medium};
  transition: background-color 0.3s ease;

  &:hover {
    // '4d' = 30% in hex
    background-color: ${({ theme, color, isActive }) => (isActive ? "none" : `${theme.colors[color]}4d`)};
  }

  ${({ theme }) => theme.mediaQueries.laptop} {
    padding: 10px 30px;
  }

  ${scaleAnimation}
  ${space}
`;
