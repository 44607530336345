import React from "react";
import { Svg } from "components";
import { SvgProps } from "../types";
import { useThemeContext } from "context";

const Icon: React.FC<SvgProps> = ({ color = "purple", ...props }) => {
  const { theme } = useThemeContext();

  return (
    <Svg viewBox="0 0 24 24" color="transparent" {...props}>
      <path
        d="M17.005 6.99788V4.99705C17.005 3.89202 16.1092 2.99622 15.0042 2.99622H7.00083C5.8958 2.99622 5 3.89202 5 4.99705V19.0029C5 20.1079 5.8958 21.0037 7.00083 21.0037H15.0042C16.1092 21.0037 17.005 20.1079 17.005 19.0029V14.0008"
        stroke={theme.colors[color]}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9 18.0025H13.0017"
        stroke={theme.colors[color]}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.4991 10.5019L12.5041 10.5069L12.5091 10.5019L12.5041 10.4969L12.4991 10.5019"
        stroke={theme.colors[color]}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.4952 10.4969L15.5002 10.5019L15.5052 10.4969L15.5002 10.4919L15.4952 10.4969"
        stroke={theme.colors[color]}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.503 10.5019L18.508 10.5069L18.513 10.5019L18.508 10.4969L18.503 10.5019"
        stroke={theme.colors[color]}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect
        x="9"
        y="6.99792"
        width="13.0054"
        height="7.00292"
        rx="2"
        stroke={theme.colors[color]}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
};

export default Icon;
