import React from "react";
import { Svg } from "components";
import { SvgProps } from "../types";
import { useThemeContext } from "context";

const Icon: React.FC<SvgProps> = ({ color = "primary", ...props }) => {
  const { theme } = useThemeContext();

  return (
    <Svg viewBox="0 0 24 24" color="transparent" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20 22H4C2.895 22 2 21.105 2 20V4C2 2.895 2.895 2 4 2H20C21.105 2 22 2.895 22 4V20C22 21.105 21.105 22 20 22Z"
        stroke={theme.colors[color]}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18 19H6C5.448 19 5 18.552 5 18V6C5 5.448 5.448 5 6 5H18C18.552 5 19 5.448 19 6V18C19 18.552 18.552 19 18 19Z"
        stroke={theme.colors[color]}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.4142 10.5858C12.1953 11.3668 12.1953 12.6332 11.4142 13.4142C10.6332 14.1953 9.36684 14.1953 8.58579 13.4142C7.80474 12.6332 7.80474 11.3668 8.58579 10.5858C9.36684 9.80474 10.6332 9.80474 11.4142 10.5858"
        stroke={theme.colors[color]}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M19 10H17" stroke={theme.colors[color]} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M19 14H17" stroke={theme.colors[color]} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </Svg>
  );
};

export default Icon;
