import styled, { css } from "styled-components";
import { border, space, typography } from "styled-system";
import { InputProps, ThemedProps } from "./types";

export const getBorderStyles = ({ error, isTouched, theme }: ThemedProps) => {
  if (error && isTouched) {
    return css`
      border: 1px solid ${theme.colors.red};
      color: ${({ theme }) => theme.colors.red};

      &:focus {
        border: 1px solid ${theme.colors.red} !important;
      }
      &:hover {
        border: 1px solid ${theme.colors.red} !important;
      }
    `;
  }

  return css`
    border: 1px solid ${theme.colors.neutral700};
  `;
};

export const Input = styled.input<InputProps>`
  background-color: transparent;
  border-radius: ${({ theme }) => theme.radii.medium};
  color: ${({ theme }) => theme.colors.neutral200};
  font-family: ${({ theme }) => theme.fonts.thicccboi};
  display: block;
  font-size: 16px;
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  height: 56px;
  outline: 0;
  padding: 0 24px;
  width: 100%;

  &::placeholder {
    color: ${({ theme }) => theme.colors.neutral500};
  }

  &:disabled {
    background-color: ${({ theme }) => theme.colors.neutral400};
    border: 1px solid ${({ theme }) => theme.colors.neutral600};
    color: ${({ theme }) => theme.colors.neutral900};
    cursor: not-allowed;
  }

  &:focus:not(:disabled) {
    border: 1px solid ${({ theme }) => theme.colors.neutral600};
  }

  &:hover:not(:disabled) {
    border: 1px solid ${({ theme }) => theme.colors.neutral600};
  }

  ${getBorderStyles}

  ${typography}
  ${space}
  ${border}
`;

Input.defaultProps = {
  scale: "md",
};
