import React from "react";
import { Svg } from "components";
import { SvgProps } from "../types";
import { useThemeContext } from "context";

const Icon: React.FC<SvgProps> = ({ color = "primary", ...props }) => {
  const { theme } = useThemeContext();

  return (
    <Svg viewBox="0 0 24 24" color="transparent" {...props}>
      <path
        d="M20.2869 3.87716C21.239 4.82926 21.239 6.37291 20.2869 7.32501C19.3348 8.27711 17.7912 8.27711 16.8391 7.32501C15.887 6.37291 15.887 4.82926 16.8391 3.87716C17.7912 2.92506 19.3348 2.92506 20.2869 3.87716"
        stroke={theme.colors[color]}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.9146 10.3632C20.7786 9.35919 20.4516 8.42119 19.9916 7.56519"
        stroke={theme.colors[color]}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.5988 4.17097C15.7428 3.71097 14.8048 3.38397 13.8008 3.24797"
        stroke={theme.colors[color]}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.16192 17.0012C8.11402 17.9533 8.11402 19.4969 7.16192 20.449C6.20983 21.4011 4.66617 21.4011 3.71407 20.449C2.76198 19.4969 2.76198 17.9533 3.71407 17.0012C4.66617 16.0491 6.20982 16.0491 7.16192 17.0012"
        stroke={theme.colors[color]}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.08537 13.9631C3.22138 14.9671 3.54837 15.9051 4.00837 16.7611"
        stroke={theme.colors[color]}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.2003 21.0782C9.19634 20.9422 8.25834 20.6152 7.40234 20.1552"
        stroke={theme.colors[color]}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.16192 3.87716C8.11402 4.82926 8.11402 6.37291 7.16192 7.32501C6.20983 8.27711 4.66617 8.27711 3.71407 7.32501C2.76198 6.37291 2.76198 4.82926 3.71407 3.87716C4.66617 2.92506 6.20982 2.92506 7.16192 3.87716"
        stroke={theme.colors[color]}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.40234 4.17098C8.25834 3.71098 9.19634 3.38298 10.2003 3.24698"
        stroke={theme.colors[color]}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.00837 7.56519C3.54837 8.42119 3.22138 9.35919 3.08537 10.3632"
        stroke={theme.colors[color]}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.2869 17.0012C21.239 17.9533 21.239 19.4969 20.2869 20.449C19.3348 21.4011 17.7912 21.4011 16.8391 20.449C15.887 19.4969 15.887 17.9533 16.8391 17.0012C17.7912 16.0491 19.3348 16.0491 20.2869 17.0012"
        stroke={theme.colors[color]}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.9916 16.7611C20.4516 15.9051 20.7786 14.9671 20.9146 13.9631"
        stroke={theme.colors[color]}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.5988 20.1552C15.7428 20.6152 14.8048 20.9422 13.8008 21.0782"
        stroke={theme.colors[color]}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 8.16357L16.0001 12.1636L12 16.1637L7.99994 12.1636L12 8.16357Z"
        stroke={theme.colors[color]}
        strokeWidth="1.49999"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
};

export default Icon;
