import React, { useContext, useState } from "react";
import { createContext, PropsWithChildren } from "react";
import { ContextType, ModalContextValue, ModalProps } from "./types";

const ModalContext = createContext<ModalContextValue | null>(null);

const ModalContextProvider: React.FC<PropsWithChildren<{}>> = ({ children }) => {
  const [modalState, setModalState] = useState<ContextType>({
    modalName: null,
  });

  const openModal = (modalProps: ContextType) => {
    setModalState(prev => ({ ...prev, ...modalProps }));
  };

  const closeModal = () => {
    setModalState({
      modalName: null,
    });
  };

  const onOutsideClick = () => {
    modalState.clickOutsideHandler?.();
    setModalState({
      modalName: null,
    });
  };

  const updateModalProps = (props: ModalProps) => {
    setModalState(prev => ({ ...prev, props }));
  };

  return (
    <ModalContext.Provider value={{ ...modalState, closeModal, openModal, onOutsideClick, updateModalProps }}>
      {children}
    </ModalContext.Provider>
  );
};

export const useModal = () => {
  const modalContext = useContext(ModalContext);

  if (modalContext === null) {
    throw new Error("Modal context is not found");
  }

  return modalContext;
};

export default ModalContextProvider;
