import React from "react";
import { useLocation } from "react-router-dom";
// Components
import { Button, Heading, Text, FlexGap, Container, ContentBlockWrapper, Column } from "components";
import { ImagesContainer } from "./components";
import { StyledContainer } from "./styled";
// Hooks
import { DIMENSIONS } from "configs";
import { useTranslation } from "context";
// Types
import { BannerSectionTypes } from "./types";
import { ROUTES } from "navigation/routes";

const BannerSection: React.FC<BannerSectionTypes> = ({
  backgroundImg,
  backgroundPositionDesktop,
  backgroundPositionMobile,
  title,
  descriptions,
  outlineButtonText = "Get your Wallet",
  primaryButtonText = "Contact Us",
  imgProps,
}) => {
  const { pathname } = useLocation();

  const { t, checkRouteOnNavigate } = useTranslation();

  const currentPathname = pathname.split("/")[1];
  const isBuyCryptoPage = currentPathname === ROUTES.buyCrypto;

  const handleClick = (path: string) => {
    checkRouteOnNavigate(`/${path}`);
  };

  return (
    <ContentBlockWrapper>
      <StyledContainer
        backgroundImg={backgroundImg}
        backgroundPositionDesktop={backgroundPositionDesktop}
        backgroundPositionMobile={backgroundPositionMobile}
      >
        <Container width="100%">
          <FlexGap
            py="60px"
            height="100%"
            gap={{ _: "0px", laptop: isBuyCryptoPage ? "20px" : "90px" }}
            alignItems="center"
            flexDirection={{ _: "column", laptop: "row" }}
          >
            <Column
              maxWidth={{ _: DIMENSIONS.TABLET_CONTENT_WRAPPER_WIDTH, laptop: "49%" }}
              justifyContent="center"
              alignItems="center"
            >
              <Heading
                scale={{ _: "h3", laptop: "h1" }}
                as="h3"
                color="neutral200"
                textAlign={{ _: "center", laptop: "start" }}
                letterSpacing="-0.01rem"
              >
                {t(title)}
              </Heading>

              <Text
                mt={{ _: "24px", laptop: "32px" }}
                textScale={{ _: "bodyM", laptop: "bodyL" }}
                color="neutral400"
                textAlign={{ _: "center", laptop: "start" }}
              >
                {t(descriptions)}
              </Text>

              <FlexGap
                width="100%"
                mt={{ _: "32px", laptop: "40px" }}
                gap={{ _: "16px", laptop: "24px" }}
                flexDirection={{ _: "column", laptop: "row" }}
              >
                <Button
                  variant="outline"
                  onClick={() => handleClick(ROUTES.accounts)}
                  scale="lg"
                  width={{ _: "100%", laptop: "auto" }}
                >
                  {t(outlineButtonText)}
                </Button>

                <Button onClick={() => handleClick(ROUTES.contacts)} scale="lg" width={{ _: "100%", laptop: "auto" }}>
                  {t(primaryButtonText)}
                </Button>
              </FlexGap>
            </Column>

            <ImagesContainer {...imgProps} />
          </FlexGap>
        </Container>
      </StyledContainer>
    </ContentBlockWrapper>
  );
};

export default BannerSection;
