import styled from "styled-components";

export const StyledContainer = styled.div<{
  backgroundImg: string;
  backgroundPositionDesktop: string;
  backgroundPositionMobile: string;
}>`
  display: flex;
  width: 100%;
  align-items: center;
  background-repeat: no-repeat;
  background-image: ${({ backgroundImg }) => backgroundImg};
  background-position: ${({ backgroundPositionMobile }) => backgroundPositionMobile};

  ${({ theme }) => theme.mediaQueries.laptop} {
    background-position: ${({ backgroundPositionDesktop }) => backgroundPositionDesktop};
  }
`;
