import React from "react";
import styled from "styled-components";

import { Text, Button, Heading } from "components";

import { useModal } from "context";

const StyledTestModal = styled.div`
  display: flex;
  height: 300px;
  width: 250px;
  padding: 16px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const TestModal: React.FC = () => {
  const { closeModal } = useModal();

  return (
    <StyledTestModal>
      <Heading textAlign="center" scale="h5" as="h5">
        Header with custom prop
      </Heading>
      <Text>This is a test modal</Text>
      <Button onClick={closeModal}>Close modal</Button>
    </StyledTestModal>
  );
};

export default TestModal;
