import React from "react";

import { StyledDropdown } from "../styled";
import { SocialLinks, Logo, ColumnLinks, Newsletter, IubendaHelper } from "components/footer/components";
import { Box, FlexGap, LanguageSelectorWithCurrentLanguage, Link, StyledLink } from "components";

import { useTranslation } from "context";

import { NAVIGATION_MENU_ITEMS } from "components/header/components/navigation-menu/constants";
import { NAVIGATE_LINKS } from "components/header/constants";
import { ROUTES } from "navigation/routes";

export const DesktopGrid: React.FC = () => {
  const { t } = useTranslation();

  return (
    <>
      <IubendaHelper />
      <>
        <FlexGap gap="16px" mb="40px">
          <Logo width="40%" />

          <ColumnLinks title="Services" links={NAVIGATION_MENU_ITEMS} width="20%" />

          <ColumnLinks title="Company" links={NAVIGATE_LINKS} width="15%" />

          <Newsletter width="25%" />
        </FlexGap>

        <FlexGap gap="16px">
          <StyledDropdown width="40%">
            <LanguageSelectorWithCurrentLanguage />
          </StyledDropdown>

          <Box width="20%">
            <Link target="_blank" href={`/${ROUTES.termsAndConditions}`} external color="neutral500">
              {t("Terms and Conditions")}
            </Link>

            <StyledLink as="a" className="iubenda-cs-uspr-link" color="neutral500">
              {t("Notice at Collection")}
            </StyledLink>
          </Box>

          <Box width="15%">
            <Link target="_blank" href={`/${ROUTES.privacyPolicy}`} external color="neutral500">
              {t("Privacy Policy")}
            </Link>

            <StyledLink as="a" className="iubenda-cs-preferences-link" color="neutral500">
              {t("Your Privacy Choices")}
            </StyledLink>
          </Box>

          <SocialLinks width="25%" />
        </FlexGap>
      </>
    </>
  );
};
