import React from "react";
import { Svg } from "components";
import { SvgProps } from "../types";
import { useThemeContext } from "context";

const Icon: React.FC<SvgProps> = ({ color = "primary", ...props }) => {
  const { theme } = useThemeContext();
  return (
    <Svg viewBox="0 0 24 24" {...props} color="transparent">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.0011 6.3501C9.31212 9.1411 9.31212 13.6421 12.0011 16.4331C14.6901 13.6431 14.6901 9.1411 12.0011 6.3501Z"
        stroke={theme.colors[color]}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.999 6.3501C9.20803 6.3501 6.95703 8.6011 6.95703 11.3921C6.95703 14.1831 9.20803 16.4341 11.999 16.4341C14.79 16.4341 17.041 14.1831 17.041 11.3921C17.041 8.6011 14.79 6.3501 11.999 6.3501V6.3501Z"
        stroke={theme.colors[color]}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.96094 11.3921H17.0409"
        stroke={theme.colors[color]}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20 11.212C20 15.58 16.843 19.705 12.52 20.928C12.182 21.024 11.818 21.024 11.48 20.928C7.157 19.705 4 15.58 4 11.212V7.21399C4 6.40199 4.491 5.66999 5.243 5.36299L10.107 3.37299C11.321 2.87599 12.681 2.87599 13.894 3.37299L18.758 5.36299C19.509 5.66999 20 6.40199 20 7.21399V11.212Z"
        stroke={theme.colors[color]}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
};

export default Icon;
