import React from "react";
import { Svg } from "components";
import { SvgProps } from "../types";
import { useThemeContext } from "context";

const Icon: React.FC<SvgProps> = ({ color = "purple", ...props }) => {
  const { theme } = useThemeContext();

  return (
    <Svg viewBox="0 0 24 24" color="transparent" {...props}>
      <path
        d="M19.9463 15.2606C21.2593 16.5735 21.2593 18.7022 19.9463 20.0152C18.6334 21.3281 16.5047 21.3281 15.1917 20.0152C13.8788 18.7022 13.8788 16.5735 15.1917 15.2606C16.5047 13.9476 18.6334 13.9476 19.9463 15.2606"
        stroke={theme.colors[color]}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.2517 7.15305L18.3537 10.0511C17.9157 10.4891 17.2067 10.4891 16.7687 10.0511L13.8707 7.15305C13.4337 6.71605 13.4337 6.00605 13.8707 5.56905L16.7687 2.67005C17.2067 2.23205 17.9157 2.23205 18.3537 2.67005L21.2517 5.56805C21.6887 6.00605 21.6887 6.71505 21.2517 7.15305V7.15305Z"
        stroke={theme.colors[color]}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.31631 9.52994H8.41431C9.03331 9.52994 9.53531 9.02794 9.53531 8.40894V4.31094C9.53531 3.69194 9.03331 3.18994 8.41431 3.18994H4.31631C3.69731 3.18994 3.19531 3.69194 3.19531 4.31094V8.40894C3.19531 9.02794 3.69731 9.52994 4.31631 9.52994Z"
        stroke={theme.colors[color]}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.36475 15.1559L2.88075 19.1319C2.38875 19.9189 2.95475 20.9399 3.88275 20.9399H8.85175C9.77975 20.9399 10.3458 19.9189 9.85375 19.1319L7.36975 15.1569C6.90675 14.4159 5.82775 14.4159 5.36475 15.1559V15.1559Z"
        stroke={theme.colors[color]}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
};

export default Icon;
