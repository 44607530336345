import styled from "styled-components";
import { Flex } from "components";

export const StyledBannerContainer = styled.div`
  display: flex;
  background-color: ${({ theme }) => theme.colors.neutral1000};
  width: 100%;
  margin-bottom: 140px;

  ${({ theme }) => theme.mediaQueries.laptop} {
    margin-bottom: 200px;
  }
`;

export const StyledBannerWrapper = styled(Flex)`
  width: 100%;
  background-color: ${({ theme }) => theme.colors.neutral900};
  border-radius: ${({ theme }) => theme.radii.semiLarge};

  background-repeat: no-repeat;
  background-image: url("/images/coins/usdt-east.webp"), url("/images/coins/bitcoin-east.webp"),
    url("/images/coins/xrp-east.webp"), url("/images/coins/matic-east.webp");
  background-size:
    125px 125px,
    210px 210px,
    140px 140px,
    80px 80px;

  background-position:
    -70px 10%,
    105% -100px,
    15% 117%,
    90% 98%;

  ${({ theme }) => theme.mediaQueries.laptop} {
    background-size:
      210px 210px,
      430px 430px,
      350px 350px,
      80px 80px;
    background-position:
      0 10%,
      110% -110px,
      0 310px,
      90% 560px;
  }
`;
