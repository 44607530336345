import React from "react";
import { Svg } from "components";
import { SvgProps } from "../types";
import { useThemeContext } from "context";

const Icon: React.FC<SvgProps> = ({ color = "neutral500", ...props }) => {
  const { theme } = useThemeContext();
  return (
    <Svg viewBox="0 0 24 24" {...props} color="transparent">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.9968 19.5661V11.0256C14.9968 10.6004 14.8277 10.1932 14.5276 9.89309L12.2646 7.63015C11.9645 7.33002 11.5573 7.16095 11.1322 7.16095H5.59285C4.70849 7.16095 3.99219 7.87725 3.99219 8.76162V19.5661C3.99219 20.4505 4.70849 21.1668 5.59285 21.1668H13.3961C14.2805 21.1668 14.9968 20.4505 14.9968 19.5661Z"
        stroke={theme.colors[color]}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.9947 17.1651H18.3961C19.2805 17.1651 19.9968 16.4488 19.9968 15.5645V7.02391C19.9968 6.59873 19.8277 6.19156 19.5276 5.89144L17.2646 3.6285C16.9645 3.32837 16.5573 3.1593 16.1322 3.1593H10.5929C9.70849 3.1593 8.99219 3.8756 8.99219 4.75997V7.16097"
        stroke={theme.colors[color]}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
};

export default Icon;
