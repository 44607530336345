import styled from "styled-components";
import { Svg, Text } from "components";
import { scaleAnimation } from "theme";

export const StyledBackButton = styled.div`
  display: flex;
  cursor: pointer;
  align-items: center;
  width: fit-content;
  ${scaleAnimation}

  &:hover {
    ${Text} {
      color: ${({ theme }) => theme.colors.primaryHover};
      transition: all 0.3s ease;
    }
    ${Svg} {
      path {
        stroke: ${({ theme }) => theme.colors.primaryHover};
        transition: all 0.3s ease;
      }
    }
  }
`;
