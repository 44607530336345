import styled, { css } from "styled-components";

export const StyledLanguageSelectorContainer = styled.div`
  width: 100%;
  max-width: unset;
  min-height: 100vh;
  padding: 22px 16px;
  border-radius: 0;
  background-color: ${({ theme }) => theme.colors.neutral900};

  ${({ theme }) => theme.mediaQueries.tablet} {
    padding: 18px;
    max-width: 592px;
    border-radius: 16px;
    min-height: 100%;
  }

  ${({ theme }) => theme.mediaQueries.laptop} {
    padding: 24px;
    max-width: 886px;
  }
`;

export const StyledLanguageSelectorInnerContainer = styled.div`
  display: flex;
  padding: 36px 0;
  flex-wrap: wrap;
  gap: 14px;

  ${({ theme }) => theme.mediaQueries.tablet} {
    padding: 36px 18px 18px;
    gap: 8px;
  }
`;

export const StyledLanguageItemWrapper = styled.div<{ isSelectedItem: boolean }>`
  padding: 8px;
  ${({ isSelectedItem }) =>
    isSelectedItem &&
    css`
      background-color: ${({ theme }) => theme.colors.neutral800};
      background-image: url("/images/circles/blue.svg");
      background-position: -18px 0;
      background-size: calc(100% + 36px);
      background-repeat: no-repeat;

      border: 1px solid ${({ theme }) => theme.gradients.languageItem};
    `}
  border-radius: 8px;

  &:hover {
    background-color: ${({ theme }) => theme.colors.neutral800};
  }

  ${({ theme }) => theme.mediaQueries.tablet} {
    padding-left: 12px;
    padding-right: 12px;
  }

  ${({ theme }) => theme.mediaQueries.laptop} {
    padding-left: 18px;
    padding-right: 18px;
  }
`;
