import Butter from "buttercms";

import { BUTTER_URL, ENDPOINTS, ENDPOINTS_BUTTER_CMS, isErrorResult, makeApiRequest } from "services";
import {
  ConfirmSubscribeNewsletterPayload,
  ContactFormPayload,
  ContactFormResponse,
  SearchPostType,
  SinglePostResponse,
  SubscribeNewsletterPayload,
  SubscribeNewsletterResponse,
} from "./types";

const butterHeaders = {
  Authorization: `Token ${process.env.REACT_APP_BUTTER_CMS_API_KEY}`,
};

const initialMetaForBlogs = { count: 0, next_page: null, previous_page: null };
export const initialDataForBlogs = { meta: initialMetaForBlogs, data: [] };

export const getBlogPosts = async (params?: Butter.PostListParams) => {
  const result = await makeApiRequest<Butter.PostListResponse>({
    method: "GET",
    url: BUTTER_URL + ENDPOINTS_BUTTER_CMS.posts,
    headers: butterHeaders,
    params,
  });

  if (isErrorResult(result)) {
    return initialDataForBlogs;
  }

  return result;
};

export const getBlogPostsBySearch = async (data: SearchPostType) => {
  const { params, query } = data;
  const result = await makeApiRequest<Butter.PostSearchResponse>({
    method: "GET",
    url: BUTTER_URL + ENDPOINTS_BUTTER_CMS.searchPosts,
    headers: butterHeaders,
    params: { query, ...params },
  });

  if (isErrorResult(result)) {
    return initialDataForBlogs;
  }

  return result;
};

const initialMetaForBlog = { next_post: null, previous_post: null };
export const initialDataForBlog = { meta: initialMetaForBlog, data: null };

export const getSingleBlogPost = async (slug?: string) => {
  const result = await makeApiRequest<SinglePostResponse>({
    method: "GET",
    url: BUTTER_URL + ENDPOINTS_BUTTER_CMS.posts + slug,
    headers: butterHeaders,
  });

  if (isErrorResult(result)) {
    return initialDataForBlog;
  }

  return result;
};

export const initialDataForCategories = { data: null };
export const getAllBlogsCategories = async (params?: Butter.CategoryParams) => {
  const result = await makeApiRequest<Butter.CategoryListResponse>({
    method: "GET",
    url: BUTTER_URL + ENDPOINTS_BUTTER_CMS.categories,
    params,
    headers: butterHeaders,
  });

  if (isErrorResult(result)) {
    return initialDataForBlog;
  }

  return result;
};

export const sendContactForm = async (data?: ContactFormPayload) => {
  const result = await makeApiRequest<ContactFormResponse>({
    method: "POST",
    url: ENDPOINTS.contactUs,
    data,
  });

  return result;
};

export const subscribeNewsletter = async (data: SubscribeNewsletterPayload) => {
  const result = await makeApiRequest<SubscribeNewsletterResponse>({
    method: "POST",
    url: ENDPOINTS.subscribeNewsletter,
    data,
  });

  return result;
};

export const confirmSubscribeNewsletter = async (data: ConfirmSubscribeNewsletterPayload) => {
  const result = await makeApiRequest<SubscribeNewsletterResponse>({
    method: "POST",
    url: ENDPOINTS.confirmSubscribeNewsletter,
    data,
  });

  return result;
};
