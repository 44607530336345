import React from "react";

import { useHandleLanguageSelectorClick } from "hooks";
import { useTranslation } from "context";

import { Flex, Text } from "components";
import { LanguageSelectorIcon } from "components/svg";

const LanguageSelectorWithCurrentLanguage: React.FC = () => {
  const { currentLanguage } = useTranslation();

  const { handleLanguageSelectorClick } = useHandleLanguageSelectorClick();

  return (
    <Flex onClick={handleLanguageSelectorClick} cursor="pointer" width="fit-content">
      <LanguageSelectorIcon width="24px" />

      <Text translate="no" textScale="bodyM" px="12px" color="neutral200" $fontWeight="medium">
        {currentLanguage.translate}
      </Text>
    </Flex>
  );
};

export default LanguageSelectorWithCurrentLanguage;
