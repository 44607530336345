import React from "react";

import { Box, InputEmail, Text } from "components";
import { ColumnHeader } from "components/footer/components";

import { useTranslation } from "context";

import { useSubscribeNewsletter } from "hooks";

import { BoxProps } from "components/layout/components/types";

export const Newsletter: React.FC<BoxProps> = props => {
  const { isLoading, handleSubscribe } = useSubscribeNewsletter();

  const { t } = useTranslation();

  return (
    <Box {...props}>
      <ColumnHeader title="newsletter" />

      <Text textScale="bodyM" mb={{ _: 24, laptop: 36 }}>
        {t("Subscribe to our newsletter to receive more articles and resources.")}
      </Text>

      <InputEmail isLoading={isLoading} onSendClick={handleSubscribe} />
    </Box>
  );
};
