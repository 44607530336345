import { css } from "styled-components";
import { system, Config } from "styled-system";

import { scales } from "./types";
import { fontWeight } from "theme/base";
import { FontWeight } from "theme/types";

export const styles = {
  [scales.bodyL]: css`
    font-size: 18px;
    line-height: 1.55;
    font-weight: 500;
  `,
  [scales.bodyM]: css`
    font-size: 16px;
    line-height: 1.625;
    font-weight: 500;
  `,
  [scales.bodyS]: css`
    font-size: 14px;
    line-height: 1.71;
    font-weight: 500;
  `,
  [scales.captionL]: css`
    font-size: 14px;
    line-height: 1.42;
    font-weight: 700;
  `,
  [scales.captionS]: css`
    font-size: 12px;
    line-height: 1.66;
    font-weight: 600;
  `,
  [scales.button]: css`
    font-size: 16px;
    line-height: 1.25;
    font-weight: 700;
  `,
};

const config: Config = {
  $fontWeight: {
    property: "fontWeight",
    transform: (value: keyof FontWeight) => {
      return fontWeight[value];
    },
  },
};

export const fontWeights = system(config);
