export const baseColors = {
  transparent: "transparent",
} as const;

export const darkColors = {
  ...baseColors,
  // Put dark colors here
  neutral1000: "#0A0D14",
  neutral900: "#1B1F27",
  neutral800: "#23272F",
  neutral700: "#3F444D",
  neutral600: "#5F6C85",
  neutral500: "#94A0B8",
  neutral400: "#CBD2E1",
  neutral300: "#E1E6EF",
  neutral200: "#F1F3F9",
  neutral100: "#F8F9FC",
  neutral0: "#FFFFFF",

  grey1: "#F7F7F7",
  grey2: "#E3E3E3",
  primary: "#3F7FFF",
  primaryHover: "#1462FF",
  primaryLight: "#EBF1FF",

  purple: "#8E59FF",
  purpleLight: "#EFEBFF",
  red: "#FF4747",
  redLight: "#FFEBEE",
  green: "#50CF7C",
  greenLight: "#ECF8EF",
  orange: "#FFAF4C",
  orangeLight: "#FFF3E5",
  pink: "#FF478A",
  pinkLight: "#FFEBF2",
} as const;
