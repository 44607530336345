import React from "react";
// Components
import { Column, Container, FlexGap, Text, Heading } from "components";
import { AccordionItem } from "./components";
// Context
import { useTranslation } from "context";
// Constants
import { FREQUENTLY_ASKED_QUESTIONS } from "./constants";
// Types
import { AskedQuestionsType } from "./types";

const FrequentlyAskedQuestions: React.FC<{ frequentlyAskedQuestions?: AskedQuestionsType[] }> = ({
  frequentlyAskedQuestions = FREQUENTLY_ASKED_QUESTIONS,
}) => {
  const { t } = useTranslation();

  return (
    <Container width="100%">
      <Column pb={{ _: "140px", laptop: "200px" }} alignItems={{ _: "start", laptop: "center" }}>
        <Heading
          px={{ _: "0px", laptop: "10px" }}
          scale={{ _: "h4", laptop: "h3" }}
          as="h4"
          color="neutral200"
          textAlign={{ _: "left", laptop: "center" }}
          letterSpacing="-0.01rem"
        >
          {t("Frequently Asked Questions")}
        </Heading>
        <Text
          mt={{ _: "24px", laptop: "32px" }}
          textScale={{ _: "bodyM", laptop: "bodyL" }}
          color="neutral400"
          textAlign={{ _: "left", laptop: "center" }}
          maxWidth="500px"
        >
          {t("We offer secure, robust and full featured ZERT system for all your digital asset needs.")}
        </Text>
        <FlexGap
          width="100%"
          mt={{ _: "40px", laptop: "72px" }}
          flexDirection="column"
          gap={{ _: "24px", laptop: "30px" }}
        >
          {frequentlyAskedQuestions.map(item => (
            <AccordionItem key={item.question} {...item} />
          ))}
        </FlexGap>
      </Column>
    </Container>
  );
};

export default FrequentlyAskedQuestions;
