import { TranslateFunction } from "context/language-context/types";
import { ROUTES } from "navigation/routes";
import { removeTrailingSlashIfExists } from "utils/pathname-helpers";

const URL = process.env.REACT_APP_URL;

export const getDefaultMeta = (t: TranslateFunction) => {
  return {
    title: "",
    description: t(
      "%name%, the secure crypto platform with DeFi tech, multilingual support & institutional grade security for everyone. Buy, store, stake and pay in crypto.",
      { name: t("ZERT") },
    ),
    image: `${URL}/logo512.png`,
    keywords: "zert, crypto, tokens",
  };
};

export const getCustomMeta = (path: string, locale: string, t: TranslateFunction) => {
  const _locale = locale ? `${locale}/` : "";

  const basePath = removeTrailingSlashIfExists(path);

  switch (basePath) {
    case `/${locale}`: {
      return {
        ...getDefaultMeta(t),
        title: t("ZERT | Simplicity meets Security | DeFi Wallet"),
      };
    }

    case `${_locale}`: {
      return {
        ...getDefaultMeta(t),
        title: t("ZERT | Simplicity meets Security | DeFi Wallet"),
      };
    }

    case `/${_locale}${ROUTES.digitalStorage}`: {
      return {
        ...getDefaultMeta(t),
        title: t("Digital Storage | Hot & Cold Deposit Box for Crypto"),
      };
    }

    case `/${_locale}${ROUTES.buyCrypto}`: {
      return {
        ...getDefaultMeta(t),
        title: t("Buy & Sell Crypto | Simplicity meets Security"),
      };
    }

    case `/${_locale}${ROUTES.staking}`: {
      return {
        ...getDefaultMeta(t),
        title: t("Crypto Staking | Simplicity meets Security"),
      };
    }

    case `/${_locale}${ROUTES.escrow}`: {
      return {
        ...getDefaultMeta(t),
        title: t("Crypto Escrow Wallet | Simplicity meets Security"),
      };
    }

    case `/${_locale}${ROUTES.security}`: {
      return {
        ...getDefaultMeta(t),
        title: t("Digital Wallet Security | Zero Effort & Risk-free"),
      };
    }

    case `/${_locale}${ROUTES.swap}`: {
      return {
        ...getDefaultMeta(t),
        title: t("Swap Crypto | Simplicity meets Security"),
      };
    }

    case `/${_locale}${ROUTES.contacts}`: {
      return {
        ...getDefaultMeta(t),
        title: t("Contact Us | ZERT Phone, Email & Social"),
      };
    }

    case `/${_locale}${ROUTES.aboutUs}`: {
      return {
        ...getDefaultMeta(t),
        title: t("About ZERT | DeFi Wallet Backed by Top Security"),
      };
    }

    case `/${_locale}${ROUTES.accounts}`: {
      return {
        ...getDefaultMeta(t),
        title: t("ZERT | Accounts for Personal, Business & Institutional"),
      };
    }

    case `/${_locale}${ROUTES.blogs}`: {
      return {
        ...getDefaultMeta(t),
        title: t("Blog"),
      };
    }

    case `/${_locale}${ROUTES.subscribeNewsletter}`: {
      return {
        ...getDefaultMeta(t),
        title: `${t("Subscribe Newsletter")}`,
      };
    }

    default:
      return {
        ...getDefaultMeta(t),
      };
  }
};
