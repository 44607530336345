import React from "react";
import { Heading } from "components";
import { StyledTab } from "./styled";
// Types
import { TabProps } from "./types";

const Tab: React.FC<TabProps> = ({ title, index, onClick, ...rest }) => {
  return (
    <StyledTab id={`navTab${index}`} scale="md" onClick={onClick} {...rest}>
      <Heading as="h3" scale="h5" ellipsis>
        {title}
      </Heading>
    </StyledTab>
  );
};

export default Tab;
