import React from "react";
import { Svg } from "components";
import { SvgProps } from "../types";
import { useThemeContext } from "context";

const Icon: React.FC<SvgProps> = ({ color = "green", ...props }) => {
  const { theme } = useThemeContext();

  return (
    <Svg viewBox="0 0 24 24" color="transparent" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.49797 9.16192H5.49672C4.66807 9.16162 3.9964 8.48995 3.99609 7.6613V4.66005C3.9964 3.8314 4.66807 3.15973 5.49672 3.15942H8.49797C9.32662 3.15973 9.99829 3.8314 9.99859 4.66005V7.6613C9.99829 8.48995 9.32662 9.16162 8.49797 9.16192Z"
        stroke={theme.colors[color]}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.5031 9.16192H14.5006C13.672 9.16162 13.0003 8.48995 13 7.6613V4.66005C13.0003 3.8314 13.672 3.15973 14.5006 3.15942H20.5031C21.3318 3.15973 22.0034 3.8314 22.0037 4.66005V7.6613C22.0034 8.48995 21.3318 9.16162 20.5031 9.16192Z"
        stroke={theme.colors[color]}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.5058 18.1656H17.5045C16.6759 18.1653 16.0042 17.4936 16.0039 16.665V13.6637C16.0042 12.8351 16.6759 12.1634 17.5045 12.1631H20.5058C21.3344 12.1634 22.0061 12.8351 22.0064 13.6637V16.665C22.0061 17.4936 21.3344 18.1653 20.5058 18.1656Z"
        stroke={theme.colors[color]}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.999 21.1669H5.99693C5.46623 21.167 4.95722 20.9562 4.58196 20.581C4.2067 20.2057 3.99595 19.6967 3.99609 19.166V14.5931C3.99595 14.0624 4.2067 13.5534 4.58196 13.1782C4.95722 12.8029 5.46623 12.5921 5.99693 12.5923H10.999C11.5297 12.5921 12.0387 12.8029 12.414 13.1782C12.7892 13.5534 13 14.0624 12.9998 14.5931V19.166C13 19.6967 12.7892 20.2057 12.414 20.581C12.0387 20.9562 11.5297 21.167 10.999 21.1669Z"
        stroke={theme.colors[color]}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
};

export default Icon;
