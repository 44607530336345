import styled from "styled-components";

export const StyledModalWrapper = styled.div`
  display: flex;
  width: 288px;
  padding: 24px;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  ${({ theme }) => theme.mediaQueries.laptop} {
    padding: 32px;
    width: 565px;
  }
`;
