import React from "react";

import { Box, Link } from "components";
import { ColumnHeader } from "components/footer/components";

import { useTranslation } from "context";

import { ColumnLinksProps } from "./types";

export const ColumnLinks: React.FC<ColumnLinksProps> = ({ title, links, ...props }) => {
  const { t, getLocalizedPath } = useTranslation();

  return (
    <Box {...props}>
      <ColumnHeader title={title} />

      {links.map(({ title, path }) => (
        <Link key={title} href={getLocalizedPath(`/${path}`)} mb="14px">
          {t(title)}
        </Link>
      ))}
    </Box>
  );
};
