import styled from "styled-components";
import { layout, LayoutProps } from "styled-system";
import { Flex } from "components";

export const StyledCardWrapper = styled(Flex)<{
  backgroundPosition: string;
  backgroundImg: string;
  backgroundSize?: string;
}>`
  width: 100%;

  ${({ theme }) => theme.mediaQueries.laptop} {
    background-color: ${({ theme }) => theme.colors.neutral900};
    border-radius: ${({ theme }) => theme.radii.semiMedium};
    background-repeat: no-repeat;
    background-image: ${({ backgroundImg }) => backgroundImg};
    background-position: ${({ backgroundPosition }) => backgroundPosition};
    background-size: ${({ backgroundSize }) => backgroundSize};
  }
`;

export const StyledCardImage = styled.div<{ cardImg: string } & LayoutProps>`
  display: flex;
  justify-content: center;
  background-repeat: no-repeat;
  background-image: ${({ cardImg }) => `url(${cardImg})`};
  background-size: 100% 100%;
  background-position: 0 0;

  ${layout}
`;
