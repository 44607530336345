import React from "react";
import { Svg } from "components";
import { SvgProps } from "../types";
import { useThemeContext } from "context";

const Icon: React.FC<SvgProps> = ({ color = "pink", ...props }) => {
  const { theme } = useThemeContext();
  return (
    <Svg viewBox="0 0 24 24" {...props} color="transparent">
      <path
        d="M11.9727 2.1001C17.4957 2.1001 21.9727 6.5771 21.9727 12.1001C21.9727 16.9381 18.5367 20.9731 13.9727 21.8991"
        stroke={theme.colors[color]}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.97266 21.9001C8.04566 21.5091 6.32366 20.5621 4.97266 19.2371"
        stroke={theme.colors[color]}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.32 7.1001C2.956 7.7281 2.652 8.3951 2.43 9.1001"
        stroke={theme.colors[color]}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.97266 3.44796C6.62466 3.64996 6.29266 3.87596 5.97266 4.11796"
        stroke={theme.colors[color]}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.02697 13.1001C2.13497 14.1481 2.40297 15.1471 2.80897 16.0741"
        stroke={theme.colors[color]}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.11819 16.9079C4.38819 14.1779 4.38719 9.75095 7.11819 7.01995C9.51019 4.62795 13.2032 4.33195 15.9182 6.13095"
        stroke={theme.colors[color]}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.8984 8.10791C18.6574 9.25391 19.0414 10.5739 19.0534 11.8969"
        stroke={theme.colors[color]}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.8672 18.713C14.3572 18.582 14.8372 18.403 15.2962 18.164"
        stroke={theme.colors[color]}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.2589 18.7129C10.5309 18.7849 10.8069 18.8369 11.0849 18.8759"
        stroke={theme.colors[color]}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.4727 16.3861C17.9377 15.8151 18.2987 15.1881 18.5567 14.5291"
        stroke={theme.colors[color]}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.9727 9.31494L14.7586 12.1009L11.9727 14.8869L9.18668 12.1009L11.9727 9.31494Z"
        stroke={theme.colors[color]}
        strokeWidth="1.49999"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
};

export default Icon;
