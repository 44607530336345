import { Loader } from "components";
import { lazyLoad } from "utils/loadable";

export const LandingPage = lazyLoad(
  () => import("./landing"),
  module => module.default,
  { fallback: <Loader /> },
);
export const DigitalStoragePage = lazyLoad(
  () => import("./digital-storage"),
  module => module.default,
  { fallback: <Loader /> },
);
export const BuyCryptoPage = lazyLoad(
  () => import("./buy-crypto"),
  module => module.default,
  { fallback: <Loader /> },
);
export const StakingPage = lazyLoad(
  () => import("./staking"),
  module => module.default,
  { fallback: <Loader /> },
);
export const EscrowPage = lazyLoad(
  () => import("./escrow"),
  module => module.default,
  { fallback: <Loader /> },
);
export const SecurityPage = lazyLoad(
  () => import("./security"),
  module => module.default,
  { fallback: <Loader /> },
);
export const SwapPage = lazyLoad(
  () => import("./swap"),
  module => module.default,
  { fallback: <Loader /> },
);
export const SingleBlogPage = lazyLoad(
  () => import("./single-blog"),
  module => module.default,
  { fallback: <Loader /> },
);
export const BlogsPage = lazyLoad(
  () => import("./blogs"),
  module => module.default,
  { fallback: <Loader /> },
);
export const ContactsPage = lazyLoad(
  () => import("./contacts"),
  module => module.default,
  { fallback: <Loader /> },
);
export const AboutUsPage = lazyLoad(
  () => import("./about-us"),
  module => module.default,
  { fallback: <Loader /> },
);
export const AccountsPage = lazyLoad(
  () => import("./accounts"),
  module => module.default,
  { fallback: <Loader /> },
);
export const PrivacyPolicyPage = lazyLoad(
  () => import("./privacy-policy"),
  module => module.default,
  { fallback: <Loader /> },
);
export const SubscribeNewsletterPage = lazyLoad(
  () => import("./newsletter-subscribe"),
  module => module.default,
  { fallback: <Loader /> },
);
export const TermsAndConditionsPage = lazyLoad(
  () => import("./terms-and-conditions"),
  module => module.default,
  { fallback: <Loader /> },
);
