import React from "react";

import { useThemeContext } from "context";

import { Svg } from "components";

import { SvgProps } from "../types";

const Icon: React.FC<SvgProps> = ({ color = "neutral500", ...props }) => {
  const { theme } = useThemeContext();

  return (
    <Svg viewBox="0 0 24 24" {...props} color="transparent">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.5 3H16.504C18.987 3 21 5.013 21 7.496V16.505C21 18.987 18.987 21 16.504 21H7.496C5.013 21 3 18.987 3 16.504V7.5C3 5.015 5.015 3 7.5 3V3Z"
        stroke={theme.colors.neutral500}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.9039 5.90625H17.9714L13.4545 11.0688L18.7683 18.0938H14.6077L11.3489 13.8331L7.62016 18.0938H5.55141L10.3827 12.5719L5.28516 5.90625H9.55141L12.497 9.80062L15.9039 5.90625ZM15.1783 16.8563H16.3239L8.92891 7.07875H7.69953L15.1783 16.8563Z"
        fill={theme.colors[color]}
      />
    </Svg>
  );
};

export default Icon;
