import React from "react";

import { FlexGap } from "components";

import { CardsWrapperProps } from "./types";

import { DIMENSIONS } from "configs";

export const CardsWrapper: React.FC<CardsWrapperProps> = ({ children, ...props }) => {
  return (
    <FlexGap
      py={{ _: "0px", laptop: "30px" }}
      width="100%"
      maxWidth={{ _: DIMENSIONS.TABLET_CONTENT_WRAPPER_WIDTH, laptop: "100%" }}
      my={{ _: "60px", laptop: "0px" }}
      gap={{ _: "60px", laptop: "30px" }}
      flexDirection={{ _: "column", laptop: "row" }}
      {...props}
    >
      {children}
    </FlexGap>
  );
};
