import * as yup from "yup";

import { useTranslation } from "context";

export const initialValues = {
  email: "",
} as const;

const useValidationSchema = () => {
  const { t } = useTranslation();

  const validationSchema = yup.object().shape({
    email: yup.string().trim().required("This field is required").email(t("Enter valid email to proceed")),
  });

  return { validationSchema, initialValues };
};

export default useValidationSchema;
