import { Router } from "@remix-run/router";

const useCheckRouter = (router: Router) => {
  router.subscribe(state => {
    (state.historyAction === "PUSH" || state.historyAction === "REPLACE") &&
      setTimeout(() => {
        window.scrollTo({ top: 0 });
      }, 0);
  });
};

export default useCheckRouter;
