import React from "react";
import { Svg } from "components";
import { SvgProps } from "../types";
import { useThemeContext } from "context";

const Icon: React.FC<SvgProps> = ({ color = "purple", ...props }) => {
  const { theme } = useThemeContext();

  return (
    <Svg viewBox="0 0 24 24" color="transparent" {...props}>
      <path
        d="M22.0036 9.6622V6.66095C22.0036 5.27967 20.8838 4.15991 19.5026 4.15991H6.49714C5.11585 4.15991 3.99609 5.27967 3.99609 6.66095V8.16158"
        stroke={theme.colors[color]}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22.0033 14.6643V17.6656C22.0033 19.0468 20.8836 20.1666 19.5023 20.1666H14"
        stroke={theme.colors[color]}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.99609 12.7634V19.5663C2.99723 20.4501 4.7877 21.1669 6.99776 21.1669C9.20782 21.1669 10.9983 20.4501 10.9994 19.5663V12.7634"
        stroke={theme.colors[color]}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.9983 12.7635C10.9983 13.6473 9.20668 14.3642 6.99662 14.3642C4.78656 14.3642 2.99609 13.6473 2.99609 12.7635C2.99609 11.8786 4.78884 11.1628 6.99776 11.1628C9.20668 11.1628 10.9983 11.8797 10.9994 12.7635"
        stroke={theme.colors[color]}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.99609 16.1648C2.99609 17.0486 4.78656 17.7655 6.99662 17.7655C9.20668 17.7655 10.9983 17.0486 10.9983 16.1648"
        stroke={theme.colors[color]}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.5049 9.66211H22.006C22.5585 9.66211 23.0064 10.11 23.0064 10.6625V13.6638C23.0064 14.2163 22.5585 14.6642 22.006 14.6642H19.5049C18.1237 14.6642 17.0039 13.5444 17.0039 12.1632V12.1632C17.0039 10.7819 18.1237 9.66211 19.5049 9.66211V9.66211Z"
        stroke={theme.colors[color]}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
};

export default Icon;
