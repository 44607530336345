import React from "react";

import { Container, Flex } from "components";
import { StyledBannerWrapper } from "./styled";
import { CoinsCardInner } from "./components";

import { CoinsCardProps } from "./types";

const CoinsCard: React.FC<CoinsCardProps> = ({
  title,
  description,
  primaryButtonText,
  outlineButtonText,
  ...props
}) => {
  return (
    <Container width="100%">
      <StyledBannerWrapper mb={{ _: "140px", laptop: "200px" }} {...props}>
        <Flex width="100%" p="100px 28px" justifyContent="center">
          <CoinsCardInner
            title={title}
            description={description}
            primaryButtonText={primaryButtonText}
            outlineButtonText={outlineButtonText}
          />
        </Flex>
      </StyledBannerWrapper>
    </Container>
  );
};

export default CoinsCard;
