import styled from "styled-components";

export const StyledCardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: ${({ theme }) => theme.radii.semiMedium};
  overflow: hidden;
  cursor: pointer;
  width: 100%;

  &:hover {
    text-decoration: none;
  }

  ${({ theme }) => theme.mediaQueries.laptop} {
    width: calc(50% - 15px);
  }
`;

export const StyledCardImg = styled.div<{ backgroundImg: string }>`
  width: 100%;
  height: 240px;
  background: ${({ backgroundImg }) => `url(${backgroundImg}) 0 50%/cover no-repeat`};

  ${({ theme }) => theme.mediaQueries.laptop} {
    height: 264px;
  }
`;
