import { css } from "styled-components";

export const appearanceAnimationVariants = {
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 },
};

export const changeOpacityOnView = ({
  isDesktop = true,
  duration = 0.5,
  delay = 0.2,
}: {
  isDesktop?: boolean;
  duration?: number;
  delay?: number;
} = {}) => {
  return {
    initial: { opacity: isDesktop ? 0 : 1 },
    whileInView: { opacity: 1 },
    transition: { delay, duration },
    viewport: { once: true },
  };
};

export const appearanceAnimationMap = {
  initial: "initial",
  animate: "animate",
  exit: "exit",
};

export const scaleAnimation = css`
  transition: all 0.3s ease;

  &:not([disabled]):active {
    transform: scale(0.95);
  }
`;
