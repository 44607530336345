import React from "react";
import { Svg } from "components";
import { SvgProps } from "../types";
import { useThemeContext } from "context";

const Icon: React.FC<SvgProps> = ({ color = "primary", ...props }) => {
  const { theme } = useThemeContext();

  return (
    <Svg viewBox="0 0 24 24" color="transparent" {...props}>
      <path
        d="M19.5832 4.40612C20.2432 5.06512 20.2432 6.13412 19.5832 6.79312C18.9232 7.45212 17.8542 7.45212 17.1942 6.79312C16.5342 6.13412 16.5342 5.06512 17.1942 4.40612C17.8542 3.74712 18.9232 3.74712 19.5832 4.40612"
        stroke={theme.colors[color]}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.4325 19.594C3.7725 18.935 3.7725 17.866 4.4325 17.207C5.0925 16.548 6.1615 16.548 6.8215 17.207C7.4815 17.866 7.4815 18.935 6.8215 19.594C6.1615 20.253 5.0915 20.253 4.4325 19.594"
        stroke={theme.colors[color]}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.94141 19.4481C10.4474 21.8301 15.2594 21.4721 18.3684 18.3661C20.1274 16.6081 21.0074 14.3041 21.0074 12.0001C21.0074 11.4681 20.9754 10.9371 20.8814 10.4121"
        stroke={theme.colors[color]}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.13381 13.5881C3.03981 13.0631 3.00781 12.5321 3.00781 12.0001C3.00781 9.69606 3.88781 7.39206 5.64681 5.63406C8.75481 2.52806 13.5678 2.17006 17.0738 4.55206"
        stroke={theme.colors[color]}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 15.7501C12 15.7501 15 14.3111 15 12.1531V9.67912L12.812 8.89212C12.286 8.70312 11.712 8.70312 11.186 8.89212L9 9.67912V12.1531C9 14.3111 12 15.7501 12 15.7501Z"
        stroke={theme.colors[color]}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
};

export default Icon;
