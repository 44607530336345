import styled from "styled-components";

import { Colors } from "theme/types";

export const StyledIconBox = styled.div<{ color: keyof Colors; marginInlineEnd?: string; cursor?: string }>`
  display: flex;
  position: relative;
  width: 40px;
  height: 40px;
  margin-inline-end: ${({ marginInlineEnd }) => marginInlineEnd ?? "18px"};
  justify-content: center;
  align-items: center;

  background-color: ${({ theme, color }) => `${theme.colors[color]}4d`};
  border-radius: ${({ theme }) => theme.radii.circle};
  cursor: ${({ cursor }) => cursor && cursor};
`;
