import React from "react";
import { Svg } from "components";
import { SvgProps } from "../types";
import { useThemeContext } from "context";

const Icon: React.FC<SvgProps> = ({ color = "purple", ...props }) => {
  const { theme } = useThemeContext();

  return (
    <Svg viewBox="0 0 24 24" color="transparent" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 22C10.025 22 12.501 19.525 12.501 16.501C12.501 13.477 10.025 11 7 11C3.975 11 1.5 13.475 1.5 16.501C1.5 17.735 1.917 18.873 2.609 19.793C3.616 21.129 5.21 22 7 22Z"
        stroke={theme.colors[color]}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.8516 19.871H16.4806C18.7286 19.871 20.5516 18.048 20.5516 15.8"
        stroke={theme.colors[color]}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.14922 4.12903H7.52022C5.27222 4.12903 3.44922 5.95203 3.44922 8.20003"
        stroke={theme.colors[color]}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.92969 2.90698L9.15069 4.12898L7.92969 5.34998"
        stroke={theme.colors[color]}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22.5 7.5C22.5 10.538 20.038 13 17 13C13.962 13 11.5 10.538 11.5 7.5C11.5 4.462 13.962 2 17 2C20.037 2 22.5 4.462 22.5 7.5"
        stroke={theme.colors[color]}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.0726 21.093L14.8516 19.872L16.0726 18.651"
        stroke={theme.colors[color]}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
};

export default Icon;
