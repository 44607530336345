export const ROUTES = {
  landing: "/",
  digitalStorage: "digital-storage",
  buyCrypto: "buy-crypto",
  staking: "staking",
  escrow: "escrow",
  security: "security",
  swap: "swap",
  contacts: "contacts",
  aboutUs: "about-us",
  blogs: "blogs",
  accounts: "accounts",
  privacyPolicy: "privacy-policy",
  termsAndConditions: "terms-and-condition",
  subscribeNewsletter: "subscribe-newsletter",
};

export const ROUTE_PARAMS = {
  slug: "slug",
  language: "language",
};
