import React from "react";
import { Svg } from "components";
import { SvgProps } from "../types";
import { useThemeContext } from "context";

const Icon: React.FC<SvgProps> = ({ color = "neutral500", ...props }) => {
  const { theme } = useThemeContext();

  return (
    <Svg viewBox="0 0 24 24" {...props} color="transparent">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.5 3H16.504C18.987 3 21 5.013 21 7.496V16.505C21 18.987 18.987 21 16.504 21H7.496C5.013 21 3 18.987 3 16.504V7.5C3 5.015 5.015 3 7.5 3V3Z"
        stroke={theme.colors[color]}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.12109 11.1001V16.5001"
        stroke={theme.colors[color]}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.7188 16.5001V13.3501C11.7188 12.1071 12.7257 11.1001 13.9688 11.1001V11.1001C15.2118 11.1001 16.2188 12.1071 16.2188 13.3501V16.5001"
        stroke={theme.colors[color]}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.11619 7.83799C7.99219 7.83799 7.89119 7.93899 7.89219 8.06299C7.89219 8.18699 7.99319 8.28799 8.11719 8.28799C8.24119 8.28799 8.34219 8.18699 8.34219 8.06299C8.34219 7.93799 8.24119 7.83799 8.11619 7.83799"
        stroke={theme.colors[color]}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
};

export default Icon;
