import React from "react";
import { Svg } from "components";
import { SvgProps } from "../types";
import { useThemeContext } from "context";

const Icon: React.FC<SvgProps> = ({ color = "orange", ...props }) => {
  const { theme } = useThemeContext();

  return (
    <Svg viewBox="0 0 24 24" color="transparent" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20 11.1819C20 15.7229 16.587 19.9689 12 20.9999C7.413 19.9689 4 15.7229 4 11.1819V7.61494C4 6.80294 4.491 6.07094 5.243 5.76394L10.243 3.71794C11.369 3.25694 12.631 3.25694 13.757 3.71794L18.757 5.76394C19.509 6.07194 20 6.80294 20 7.61494V11.1819V11.1819Z"
        stroke={theme.colors[color]}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.25 10.125L11.5 13.875L9.25 11.625"
        stroke={theme.colors[color]}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
};

export default Icon;
