import React from "react";

// Components
import { Button, Heading, Column, FlexGap, Flex, NewsCard, Skeleton } from "components";
// Context
import { useTranslation } from "context";
// Hooks
import { useMatchBreakpoints } from "hooks";
// Constants
import { DIMENSIONS } from "configs";
import { ROUTES } from "navigation/routes";
import { ArticlesType } from "./types";

const Articles: React.FC<ArticlesType> = ({ posts, isLoading, title }) => {
  const { t, checkRouteOnNavigate } = useTranslation();
  const { isDesktop } = useMatchBreakpoints();

  const browseClickHandler = () => {
    checkRouteOnNavigate(`/${ROUTES.blogs}`);
  };

  return (
    <Flex flex="1" justifyContent="center">
      <Column flex="1" alignItems="center" maxWidth={{ _: DIMENSIONS.TABLET_CONTENT_WRAPPER_WIDTH, laptop: "100%" }}>
        <Flex width="100%" justifyContent="space-between">
          <Heading scale={{ _: "h4", laptop: "h3" }} as="h4" color="neutral200">
            {title}
          </Heading>

          {isDesktop && (
            <Button scale="lg" variant="outline" onClick={browseClickHandler} disabled={isLoading || !posts.length}>
              {t("Browse articles")}
            </Button>
          )}
        </Flex>

        <FlexGap
          width="100%"
          mt={{ _: "32px", laptop: "70px" }}
          gap="30px"
          flexDirection={{ _: "column", laptop: "row" }}
        >
          {isLoading ? (
            Array.from({ length: 2 }).map((_, index) => (
              <Skeleton height={{ _: "236px", laptop: "382px" }} width={{ _: "100%", laptop: "50%" }} key={index} />
            ))
          ) : !posts.length ? (
            <Flex height={{ _: "200px", laptop: "380px" }} width="100%" justifyContent="center" alignItems="center">
              <Heading scale="h4" as="h4">
                {t("There are no articles & news at the moment")}
              </Heading>
            </Flex>
          ) : (
            posts.map((post, index) => <NewsCard key={`${post.title}-${index}`} post={post} />)
          )}
        </FlexGap>

        {!isDesktop && (
          <Button
            mt="36px"
            scale="lg"
            variant="outline"
            width="100%"
            maxWidth={DIMENSIONS.TABLET_CONTENT_WRAPPER_WIDTH}
            onClick={browseClickHandler}
            disabled={isLoading || !posts.length}
          >
            {t("Browse articles")}
          </Button>
        )}
      </Column>
    </Flex>
  );
};

export default Articles;
