import React from "react";
import Butter from "buttercms";

import { StyledTagsWrapper } from "./styled";
import Tag from "../tag";

const TagsWrapper: React.FC<{ tags: Butter.Tag[] }> = ({ tags }) => {
  return (
    <StyledTagsWrapper>
      {tags.map(({ name }, index) => (
        <Tag key={index} title={name} />
      ))}
    </StyledTagsWrapper>
  );
};

export default TagsWrapper;
