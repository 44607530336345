import React from "react";
import { Svg } from "components";
import { SvgProps } from "../types";
import { useThemeContext } from "context";

const Icon: React.FC<SvgProps> = ({ color = "pink", ...props }) => {
  const { theme } = useThemeContext();

  return (
    <Svg viewBox="0 0 24 24" {...props} color="transparent">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.1213 4.03001L3.21726 19.047C2.86926 19.707 3.34826 20.5 4.09426 20.5H19.9023C20.6483 20.5 21.1263 19.707 20.7793 19.047L12.8753 4.03001C12.5033 3.32301 11.4933 3.32301 11.1213 4.03001Z"
        stroke={theme.colors[color]}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
};

export default Icon;
