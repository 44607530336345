import React from "react";

import { Box, Text } from "components";
import { StyledClickItem } from "components/header/styled";
import { LogoWithTextIcon } from "components/svg";

import { useTranslation } from "context";
import { ROUTES } from "navigation/routes";

import { BoxProps } from "components/layout/components/types";

export const Logo: React.FC<BoxProps> = props => {
  const { t, checkRouteOnNavigate } = useTranslation();

  const onLogoClick = () => {
    checkRouteOnNavigate(ROUTES.landing);
  };

  return (
    <Box {...props}>
      <StyledClickItem onClick={onLogoClick}>
        <LogoWithTextIcon width="120px" cursor="pointer" />
      </StyledClickItem>

      <Box my="30px">
        <Text textScale="bodyM" color="neutral500">
          {t("Copyright © Zert and Escrypto.")}
        </Text>

        <Text textScale="bodyM" color="neutral500">
          {t("All rights reserved")}
        </Text>
      </Box>
    </Box>
  );
};
