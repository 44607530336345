import styled from "styled-components";
import { scaleAnimation } from "theme";

export const StyledContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.neutral900};
  position: fixed;
  top: 0;
  left: 0;
  z-index: ${({ theme }) => theme.zIndices.header};
  width: 100%;
`;

export const StyledWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  padding: 13px 0;
  min-height: 72px;

  ${({ theme }) => theme.mediaQueries.laptop} {
    padding: 28px 0;
  }
`;

export const StyledClickItem = styled.div`
  width: fit-content;
  ${scaleAnimation}
`;
