import React from "react";

import { Text } from "components";
import { StyledBackButton } from "./styled";

import { ArrowIcon } from "components/svg";

import { BackButtonProps } from "./types";

const BackButton: React.FC<BackButtonProps> = ({ title, onClick }) => {
  return (
    <StyledBackButton onClick={onClick}>
      <ArrowIcon width="24px" rotate="180deg" />
      <Text mx="8px" textScale="button">
        {title}
      </Text>
    </StyledBackButton>
  );
};

export default BackButton;
