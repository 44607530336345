import { Column, StyledIconBox, Button, FlexGap, Heading, Text } from "components";
import { StyledReleaseWrapper } from "./styled";
import { StyledValueCardWrapper } from "../";
// Context
import { useTranslation } from "context";
import { PreferenceItem } from "./components";
// Constants
import { LINKS } from "configs";
// Types
import { AccountCardProps, CardButtonExternalProps } from "./types";

const AccountCard: React.FC<AccountCardProps> = ({
  title,
  description,
  icon: Icon,
  color,
  backgroundImg,
  position,
  backgroundSize,
  accountPreferences,
  releaseDate,
}) => {
  const { t } = useTranslation();

  const externalButtonProps = !releaseDate
    ? ({ as: "a", external: true, href: LINKS.zertMain } as CardButtonExternalProps)
    : {};

  return (
    <StyledValueCardWrapper
      position="relative"
      p={{ _: "24px 18px", laptop: "24px" }}
      backgroundPosition={position}
      backgroundImg={backgroundImg}
      backgroundSize={backgroundSize}
    >
      {releaseDate && (
        <StyledReleaseWrapper>
          <Text textScale="captionS" textTransform="uppercase" color="neutral200">
            {t(releaseDate)}
          </Text>
        </StyledReleaseWrapper>
      )}
      <Column mt={{ _: "12px", laptop: "26px" }} width="100%" alignItems="center">
        <StyledIconBox color={color}>
          <Icon color={color} />
        </StyledIconBox>

        <Heading mt={{ _: "18px", laptop: "16px" }} scale="h5" as="h3" color="neutral200">
          {t(title)}
        </Heading>

        <Text mt="14px" textScale="bodyM" color="neutral400" textAlign="center">
          {t(description)}
        </Text>

        <FlexGap
          mt={{ _: "32px", laptop: "36px" }}
          width="100%"
          gap={{ _: "12px", laptop: "15px" }}
          flexDirection="column"
        >
          {accountPreferences.map(item => (
            <PreferenceItem key={item} preference={item} />
          ))}
        </FlexGap>
        <Button
          {...externalButtonProps}
          disabled={!!releaseDate}
          mt="32px"
          scale="lg"
          width={{ _: "100%", laptop: "240px" }}
        >
          {t("Start now")}
        </Button>
      </Column>
    </StyledValueCardWrapper>
  );
};

export default AccountCard;
