import React from "react";

import { StyledWrapper, StyledMenuItem } from "./styled";
import { Text, StyledIconBox, Link } from "components";

import { useTranslation } from "context";
import { NAVIGATION_MENU_ITEMS } from "./constants";

import { NavigationMenuProps } from "./types";

export const NavigationMenu: React.FC<NavigationMenuProps> = ({ onClick }) => {
  const { t, getLocalizedPath } = useTranslation();

  return (
    <StyledWrapper>
      {NAVIGATION_MENU_ITEMS.map(({ title, icon: Icon, color, path }) => (
        <Link key={title} width="100%" href={getLocalizedPath(`/${path}`)} onClick={onClick}>
          <StyledMenuItem color={color}>
            <StyledIconBox color={color}>
              <Icon />
            </StyledIconBox>

            <Text textScale="bodyM">{t(title)}</Text>
          </StyledMenuItem>
        </Link>
      ))}
    </StyledWrapper>
  );
};
