import React, { useEffect } from "react";
import { Tab, Flex } from "components";
import { StyledTabPanelWrapper } from "./styled";
// Hooks
import { useMatchBreakpoints } from "hooks";
// Types
import { TabsPanelProps } from "./types";

const PADDING = 16;

const TabsPanel: React.FC<TabsPanelProps> = ({ buttons, idForTabPanel, activeTab, gap, onTabClick }) => {
  const { isDesktop } = useMatchBreakpoints();

  const element = idForTabPanel ? document.getElementById(idForTabPanel) : null;
  const wrapper = document.getElementById("wrapper");
  const tabPanelGap = gap ? parseInt(gap) : isDesktop ? 40 : 36;

  const getMaxWidthOfTabs = () => {
    const tabsWidthArray: number[] = [];

    if (element) {
      element.childNodes.forEach(el => {
        if (el instanceof HTMLElement) {
          tabsWidthArray.push(el.offsetWidth);
        }
      });
    }

    return Math.max(...tabsWidthArray);
  };

  const scroll = (index: number) => {
    if (element && wrapper) {
      const containerWidth = wrapper.offsetWidth;
      // width from left start of page to tab item
      const centerOfPage = (containerWidth - getMaxWidthOfTabs()) / 2;
      element.scrollTo({
        left: index > 0 ? (tabPanelGap + getMaxWidthOfTabs()) * index - centerOfPage + PADDING : 0,
        behavior: "smooth",
      });
    }
  };

  useEffect(() => {
    scroll(activeTab);
  }, [element]);

  return (
    <Flex id="wrapper" position="relative" justifyContent="center" width="100%" height="54px">
      <StyledTabPanelWrapper
        id={idForTabPanel}
        px={{ _: "16px", laptop: "0px" }}
        gap={`${tabPanelGap}px`}
      >
        {buttons.map(({ title, ...rest }, index) => (
          <Tab
            index={index}
            key={title}
            title={title}
            onClick={() => {
              scroll(index);
              onTabClick(index);
            }}
            isActive={activeTab === index}
            {...rest}
          />
        ))}
      </StyledTabPanelWrapper>
    </Flex>
  );
};

export default TabsPanel;
