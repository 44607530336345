import React from "react";
import { Route, Routes } from "react-router-dom";

import { ROUTES, ROUTE_PARAMS } from "./routes";

import { NotFoundPage } from "components";
import {
  LandingPage,
  DigitalStoragePage,
  BuyCryptoPage,
  SwapPage,
  StakingPage,
  EscrowPage,
  SecurityPage,
  BlogsPage,
  SingleBlogPage,
  ContactsPage,
  AboutUsPage,
  AccountsPage,
  PrivacyPolicyPage,
  SubscribeNewsletterPage,
  TermsAndConditionsPage,
} from "pages";
import { MainOutlet } from "components/outlets";
import { LINKS } from "configs";

const Navigation: React.FC = () => {
  return (
    <Routes>
      <Route path={ROUTES.landing} element={<MainOutlet />}>
        <Route path={`:${ROUTE_PARAMS.language}?`}>
          <Route index element={<LandingPage />} />

          <Route path={ROUTES.digitalStorage} element={<DigitalStoragePage />} />

          <Route path={ROUTES.buyCrypto} element={<BuyCryptoPage />} />

          <Route path={ROUTES.staking} element={<StakingPage />} />

          <Route path={ROUTES.swap} element={<SwapPage />} />

          <Route path={ROUTES.escrow} element={<EscrowPage />} />

          <Route path={ROUTES.security} element={<SecurityPage />} />

          <Route path={ROUTES.blogs}>
            <Route index element={<BlogsPage />} />

            <Route path={`:${ROUTE_PARAMS.slug}`}>
              <Route index element={<SingleBlogPage />} />
            </Route>
          </Route>
          <Route path={ROUTES.contacts} element={<ContactsPage />} />

          <Route path={ROUTES.aboutUs} element={<AboutUsPage />} />

          <Route path={ROUTES.accounts} element={<AccountsPage />} />
        </Route>
      </Route>

      <Route path={ROUTES.landing}>
        <Route path={`:${ROUTE_PARAMS.language}?`}>
          <Route path={ROUTES.subscribeNewsletter} element={<SubscribeNewsletterPage />} />
        </Route>
      </Route>

      <Route path={ROUTES.privacyPolicy} element={<PrivacyPolicyPage href={LINKS.privacyPolicy} />} />

      <Route path={ROUTES.termsAndConditions} element={<TermsAndConditionsPage />} />

      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
};

export default Navigation;
