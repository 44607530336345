export const FREQUENTLY_ASKED_QUESTIONS = [
  {
    question: "What should I know about Zert?",
    answerText:
      "Zert crypto wallet ensures Zero Effort Risk-free Transactions. Buy, swap, and store crypto with ease using hot or cold wallets. The other cornerstones of Zert? Our Institutional-grade vault, segregated accounts, and local law compliance in USA, Canada, Portugal, and UAE.",
  },
  {
    question: "Is there a minimum deposit required?",
    answerText:
      "There's no minimum deposit. Receive crypto and connect via WalletConnect to numerous wallets. When buying crypto, the minimum transaction amount depends on the currency. Similarly, during Swap, the minimum transaction may vary.",
  },
  {
    question: "How do I use the cold wallet feature on Zert?",
    answerText:
      "Zert's cold wallet, powered by Fireblocks, uses Air-gap technology to keep your crypto offline. It's an institutional-grade system that doesn't require a hardware device. Enjoy the highest security for free for the first 3 months",
  },
  {
    question: "Can I connect my hardware wallet?",
    answerText:
      "You have the option to connect a hardware wallet like Ledger to our platform. However, rest assured that our Safety Deposit Box, also known as the Cold Wallet, is highly secure and doesn't require any additional hardware devices apart from web access. Your funds are protected.",
  },
  {
    question: "What steps have you taken to secure and safeguard my crypto assets?",
    answerText:
      "There are a number of actions we take to emphasize security, starting with the fact that all assets leaving your hot wallet receive coverage up to $10,000 per transaction. Our vaults, powered by Fireblocks, utilize MPC technology to prevent access using a singular device, making it significantly more difficult for anyone unauthorized to access your account. We’re also compatible with cold wallets. Best of all, we partner with the theft protection specialists at Coinover to monitor accounts for suspicious transactions.",
  },
];
