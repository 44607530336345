import styled from "styled-components";
import { Input } from "../input";

export const StyledInput = styled(Input)`
  border-radius: 74px;
  padding-inline-end: 52px !important;
`;

export const InputIcon = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  position: absolute;
  top: 0;
  inset-inline-end: 4px;
`;
