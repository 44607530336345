import React from "react";

import { Column, Heading, Flex, InputEmail, Text, FlexGap, Button } from "components";

import { useSubscribeNewsletter } from "hooks";
import { useTranslation } from "context";

import { CoinsCardProps } from "components/cards/coins-card/types";

import { DIMENSIONS } from "configs";
import { ROUTES } from "navigation/routes";

const CoinsCardInner: React.FC<CoinsCardProps> = ({
  title,
  description,
  primaryButtonText,
  outlineButtonText,
  ...props
}) => {
  const { isLoading, handleSubscribe } = useSubscribeNewsletter();
  const { t, checkRouteOnNavigate } = useTranslation();

  const handleAccountsClick = () => {
    checkRouteOnNavigate(`/${ROUTES.accounts}`);
  };

  const handleContactClick = () => {
    checkRouteOnNavigate(`/${ROUTES.contacts}`);
  };

  return (
    <Column
      maxWidth={{ _: DIMENSIONS.TABLET_CONTENT_WRAPPER_WIDTH, laptop: "565px" }}
      alignItems={{ _: "start", laptop: "center" }}
      {...props}
    >
      <Heading
        px={{ _: "0px", laptop: "10px" }}
        scale={{ _: "h4", laptop: "h3" }}
        as="h2"
        color="neutral200"
        textAlign={{ _: "left", laptop: "center" }}
        letterSpacing="-0.01rem"
      >
        {t(title)}
      </Heading>
      <Text
        m={{ _: "28px 0 32px", laptop: "32px 0 40px" }}
        textScale="bodyM"
        color="neutral400"
        textAlign={{ _: "left", laptop: "center" }}
      >
        {t(description)}
      </Text>

      {primaryButtonText && outlineButtonText ? (
        <FlexGap
          width="100%"
          gap={{ _: "18px", laptop: "24px" }}
          flexDirection={{ _: "column", laptop: "row" }}
          justifyContent="center"
        >
          <Button variant="outline" onClick={handleAccountsClick} scale="lg" width={{ _: "100%", laptop: "auto" }}>
            {t(outlineButtonText)}
          </Button>
          <Button onClick={handleContactClick} scale="lg" width={{ _: "100%", laptop: "auto" }}>
            {t(primaryButtonText)}
          </Button>
        </FlexGap>
      ) : (
        <Flex width="100%" justifyContent="center">
          <InputEmail isLoading={isLoading} onSendClick={handleSubscribe} />
        </Flex>
      )}
    </Column>
  );
};

export default CoinsCardInner;
