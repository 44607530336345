export const formatDate = (dateString: Date) => {
  const date = new Date(dateString);

  const options: Intl.DateTimeFormatOptions = { month: "long", day: "numeric", year: "numeric" };
  const formattedDate = date.toLocaleDateString("en-US", options);

  const ordinalSuffix = (day: number) => {
    if (day >= 11 && day <= 13) {
      return "th";
    }
    switch (day % 10) {
      case 1:
        return "st";
      case 2:
        return "nd";
      case 3:
        return "rd";
      default:
        return "th";
    }
  };

  const day = date.getDate();
  const formattedDay = `${day}${ordinalSuffix(day)}`;

  const finalFormattedDate = formattedDate.replace(`${day}`, formattedDay);

  return finalFormattedDate;
};
