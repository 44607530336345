import { motion } from "framer-motion";
import styled from "styled-components";
import { borderRadius } from "styled-system";

import { Box } from "components";

export const StyledModalWrapper = styled(motion.div)`
  background-color: ${({ theme }) => theme.colors.transparent};
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: grid;
  overflow: auto;
  justify-content: center;
  align-items: center;
  z-index: ${({ theme }) => theme.zIndices.modal};
  cursor: pointer;
  overflow-x: hidden;
  backdrop-filter: blur(5px);
`;

export const StyledModalContainer = styled(Box)`
  background-color: ${({ theme }) => theme.colors.neutral800};
  border-radius: ${({ theme }) => theme.radii.semiMedium};
  border: 1px solid ${({ theme }) => theme.colors.neutral600};

  ${({ theme }) => theme.mediaQueries.tablet} {
    margin: 24px 16px;
  }

  ${borderRadius}
`;
