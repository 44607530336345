import React from "react";
import { Svg } from "components";
import { SvgProps } from "../types";
import { useThemeContext } from "context";

const Icon: React.FC<SvgProps> = ({ color = "green", ...props }) => {
  const { theme } = useThemeContext();
  return (
    <Svg viewBox="0 0 24 24" {...props} color="transparent">
      <path
        d="M2.99609 7.49826H21.0036"
        stroke={theme.colors[color]}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.50104 11.5H7"
        stroke={theme.colors[color]}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.99901 17.5024H5.99734C4.3398 17.5024 2.99609 16.1587 2.99609 14.5012V6.49783C2.99609 4.84029 4.3398 3.49658 5.99734 3.49658H18.0023C19.6599 3.49658 21.0036 4.84029 21.0036 6.49783V10.4995"
        stroke={theme.colors[color]}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.0002 20.5035V15.9216"
        stroke={theme.colors[color]}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.0041 20.5035V15.9216"
        stroke={theme.colors[color]}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.0041 20.5035V15.9216"
        stroke={theme.colors[color]}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13 20.5036H21.0033"
        stroke={theme.colors[color]}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13 15.9217H21.0033V14.1379L17.0017 12.5002L13 14.1379V15.9217Z"
        stroke={theme.colors[color]}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
};

export default Icon;
