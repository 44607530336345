import React from "react";
import { Svg } from "components";
import { SvgProps } from "../types";
import { useThemeContext } from "context";

const Icon: React.FC<SvgProps> = ({ color = "orange", ...props }) => {
  const { theme } = useThemeContext();

  return (
    <Svg viewBox="0 0 24 24" color="transparent" {...props}>
      <path
        d="M6.39844 8.343C9.52244 5.219 14.5884 5.219 17.7124 8.343"
        stroke={theme.colors[color]}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.667 16.667C13.194 16.667 12 15.473 12 14"
        stroke={theme.colors[color]}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.2316 18C10.1086 17.028 9.39062 15.604 9.39062 14C9.39062 12.527 10.5846 11.333 12.0576 11.333C13.5306 11.333 14.7246 12.527 14.7246 14"
        stroke={theme.colors[color]}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.79766 18C7.11566 16.823 6.72266 15.458 6.72266 14C6.72266 11.054 9.11066 8.66699 12.0557 8.66699C15.0007 8.66699 17.3887 11.055 17.3887 14"
        stroke={theme.colors[color]}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3 17V19C3 20.105 3.895 21 5 21H7"
        stroke={theme.colors[color]}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21 7V5C21 3.895 20.105 3 19 3H17"
        stroke={theme.colors[color]}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7 3H5C3.895 3 3 3.895 3 5V7"
        stroke={theme.colors[color]}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17 21H19C20.105 21 21 20.105 21 19V17"
        stroke={theme.colors[color]}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
};

export default Icon;
