import styled from "styled-components";
import { Colors } from "theme/types";

export const StyledBlockWrapper = styled.div<{ offsetHeight: number; $backgroundColor?: keyof Colors }>`
  display: flex;
  min-height: 100vh;
  overflow: hidden;
  background-color: ${({ theme, $backgroundColor }) =>
    $backgroundColor ? $backgroundColor : theme.colors.neutral1000};
  padding-top: ${({ offsetHeight }) => `${offsetHeight}px`};
`;
