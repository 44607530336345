import React from "react";
import { Svg } from "components";
import { SvgProps } from "../types";
import { useThemeContext } from "context";

const Icon: React.FC<SvgProps> = ({ color = "red", ...props }) => {
  const { theme } = useThemeContext();
  return (
    <Svg viewBox="0 0 26 26" {...props} color="transparent">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.19807 8.55982L10.6809 4.49658L14.4357 8.87725L16.8653 6.04268L18.802 8.30217C20.0465 9.75406 20.7305 11.6032 20.7305 13.5155V13.7731C20.7305 15.8234 19.9161 17.7897 18.4663 19.2394C17.0166 20.6892 15.0503 21.5037 13 21.5037V21.5037C8.73059 21.5037 5.26953 18.0426 5.26953 13.7732V13.7732C5.26953 11.8609 5.95359 10.0117 7.19807 8.55982Z"
        stroke={theme.colors[color]}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
};

export default Icon;
