import React from "react";
import { motion } from "framer-motion";

import { StyledClickItem, StyledContainer, StyledWrapper } from "./styled";
import { FlexGap, Button, Container, Link, LanguageSelectorWithCurrentLanguage } from "components";
import { LanguageSelectorIcon, LogoWithTextIcon } from "components/svg";
import { BurgerMenu, MobileMenu, NavigationDropdown } from "./components";

import { useTranslation } from "context";
import { useHandleLanguageSelectorClick, useMatchBreakpoints } from "hooks";

import { NAVIGATE_LINKS } from "./constants";
import { LINKS } from "configs";
import { ROUTES } from "navigation/routes";
import { slideVariants } from "./animations";

import { HeaderProps } from "./types";

const Header: React.FC<HeaderProps> = ({ isOpen, setIsOpen }) => {
  const { t, getLocalizedPath } = useTranslation();
  const { isDesktop } = useMatchBreakpoints();

  const { handleLanguageSelectorClick } = useHandleLanguageSelectorClick();

  return (
    <StyledContainer id="header">
      <Container>
        <StyledWrapper>
          <motion.div initial="hidden" animate={isOpen ? "visible" : "hidden"}>
            <div>
              <motion.div initial="hidden" animate={isOpen ? "visible" : "hidden"} variants={slideVariants}>
                <LanguageSelectorWithCurrentLanguage />
              </motion.div>

              <motion.div initial="hidden" animate={!isOpen ? "visible" : "hidden"} variants={slideVariants}>
                <Link href={getLocalizedPath(ROUTES.landing)} reloadDocument>
                  <StyledClickItem>
                    <LogoWithTextIcon width="120px" cursor="pointer" />
                  </StyledClickItem>
                </Link>
              </motion.div>
            </div>
          </motion.div>
          {isDesktop && (
            <FlexGap gap="32px" px="32px" justifyContent="center" alignItems="center">
              <NavigationDropdown />

              {NAVIGATE_LINKS.map(({ title, path }) => {
                return (
                  <Link key={title} href={getLocalizedPath(`/${path}`)} color="neutral0">
                    {t(title)}
                  </Link>
                );
              })}
            </FlexGap>
          )}

          {isDesktop && (
            <FlexGap alignItems="center" gap="32px">
              <LanguageSelectorIcon width="24px" cursor="pointer" onClick={handleLanguageSelectorClick} />

              <Button as="a" external href={LINKS.zertMain} variant="outline">
                {t("Sign Up")}
              </Button>
            </FlexGap>
          )}

          {!isDesktop && <BurgerMenu isOpen={isOpen} setIsOpen={setIsOpen} />}
        </StyledWrapper>
      </Container>

      <MobileMenu isOpen={isOpen} setIsOpen={setIsOpen} />
    </StyledContainer>
  );
};

export default Header;
