import React from "react";
import { Svg } from "components";
import { SvgProps } from "../types";
import { useThemeContext } from "context";

const Icon: React.FC<SvgProps> = ({ color = "neutral200", ...props }) => {
  const { theme } = useThemeContext();

  return (
    <Svg viewBox="0 0 24 24" {...props} color="transparent">
      <path
        d="M12.0068 20.999C7.03659 20.9987 3.00763 16.9694 3.00781 11.9992C3.008 7.02895 7.03726 3 12.0075 3C16.9777 3 21.0068 7.02928 21.0068 11.9995"
        stroke={theme.colors[color]}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.51562 8.99972H20.4226"
        stroke={theme.colors[color]}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.51562 14.9997H12.0016"
        stroke={theme.colors[color]}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.9996 11.9997C16.0071 9.20613 15.2582 6.46258 13.8325 4.06018C13.4552 3.40458 12.7564 3.00049 12 3.00049C11.2436 3.00049 10.5448 3.40458 10.1675 4.06018C7.2775 8.95909 7.2775 15.0414 10.1675 19.9403C10.5464 20.5945 11.2444 20.9982 12.0005 21.0002"
        stroke={theme.colors[color]}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.001 3.001C16.9712 3.00128 21.0002 7.03061 21 12.0008C20.9998 16.9711 16.9706 21 12.0003 21C7.03011 21 3.001 16.9707 3.001 12.0005"
        stroke={theme.colors[color]}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.4844 15.0003L3.57737 15.0003"
        stroke={theme.colors[color]}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.4844 9.00028L11.9984 9.00028"
        stroke={theme.colors[color]}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.00044 12.0003C7.99293 14.7939 8.74175 17.5374 10.1675 19.9398C10.5448 20.5954 11.2436 20.9995 12 20.9995C12.7564 20.9995 13.4552 20.5954 13.8325 19.9398C16.7225 15.0409 16.7225 8.95862 13.8325 4.0597C13.4536 3.40547 12.7556 3.00184 11.9995 2.99976"
        stroke={theme.colors[color]}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
};

export default Icon;
