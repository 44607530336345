import React from "react";

import { Text } from "components";

import { useTranslation } from "context";

export const ColumnHeader: React.FC<{ title: string }> = ({ title }) => {
  const { t } = useTranslation();

  return (
    <Text textScale="captionL" textTransform="uppercase" color="neutral500" mb={{ _: 24, laptop: 36 }}>
      {t(title)}
    </Text>
  );
};
