import React from "react";
import { Svg } from "components";
import { SvgProps } from "../types";
import { useThemeContext } from "context";

const Icon: React.FC<SvgProps> = ({ color = "pink", ...props }) => {
  const { theme } = useThemeContext();

  return (
    <Svg viewBox="0 0 24 24" color="transparent" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.944 19H5.056C4.473 19 4 18.527 4 17.944V10.556C4 9.973 4.473 9.5 5.056 9.5H6.944C7.527 9.5 8 9.973 8 10.556V17.944C8 18.527 7.527 19 6.944 19V19Z"
        stroke={theme.colors[color]}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8 10.5721L11.649 5.82106C12.328 4.93606 13.654 4.91406 14.363 5.77506V5.77506C14.628 6.09606 14.772 6.50006 14.772 6.91606V10.1871H17.868C18.469 10.1871 19.03 10.4871 19.364 10.9861L19.693 11.4771C19.988 11.9181 20.074 12.4671 19.927 12.9761L18.568 17.6981C18.346 18.4691 17.641 19.0001 16.839 19.0001H10.55C10.05 19.0001 9.572 18.7921 9.232 18.4261L8 17.1001"
        stroke={theme.colors[color]}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
};

export default Icon;
