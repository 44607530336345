import React, { useState } from "react";
// Components
import { Heading, Text, Flex, TabsPanel, Column, Container } from "components";
import { ActiveStepItem } from "./components";
import { StyledContainer, StyledTab } from "./styled";
// Context
import { useTranslation } from "context";
// Types
import { StepsSectionType } from "./types";
// Constants
import { DIMENSIONS } from "configs";

const StepsSection: React.FC<StepsSectionType> = ({ title, description, backgroundImg, steps }) => {
  const [activeStep, setActiveStep] = useState(0);
  const { t } = useTranslation();

  const stepButtons = Array.from({ length: steps.length }).map((_, index) => ({
    title: t("Step %count%", { count: index + 1 }),
    disabled: steps[index].disabled,
  }));

  return (
    <StyledContainer imgUrl={backgroundImg}>
      <Container width="100%">
        <Flex width="100%" justifyContent="center">
          <Column width="100%" alignItems="center" overflowX="hidden">
            <Heading
              maxWidth={DIMENSIONS.TABLET_CONTENT_WRAPPER_WIDTH}
              scale={{ _: "h4", laptop: "h3" }}
              as="h3"
              color="neutral200"
              textAlign="center"
            >
              {t(title)}
            </Heading>

            {description ? (
              <Text
                maxWidth={DIMENSIONS.TABLET_CONTENT_WRAPPER_WIDTH}
                mt={{ _: "24px", laptop: "32px" }}
                textScale={{ _: "bodyS", laptop: "bodyL" }}
                color="neutral400"
                textAlign="center"
              >
                {t(description)}
              </Text>
            ) : null}

            <Flex width="100%" justifyContent="center" mt={{ _: "40px", laptop: "32px" }}>
              <TabsPanel
                idForTabPanel="stepButtons"
                buttons={stepButtons}
                activeTab={activeStep}
                onTabClick={setActiveStep}
              />
            </Flex>

            {steps.map((el, index) => (
              <StyledTab key={index} active={activeStep === index}>
                <ActiveStepItem {...el} />
              </StyledTab>
            ))}
          </Column>
        </Flex>
      </Container>
    </StyledContainer>
  );
};

export default StepsSection;
