import React, { useEffect } from "react";
import { AnimatePresence, usePresence } from "framer-motion";

import { ModalWrapper } from "./components";

import { useModal } from "context";

import { component } from "./constants";

const Modal: React.FC = () => {
  const { modalName, onOutsideClick } = useModal();
  const [isPresent, safeToRemove] = usePresence();

  useEffect(() => {
    !isPresent && setTimeout(safeToRemove, 1000);
  }, [isPresent]);

  const ModalComponent = modalName ? component[modalName] : null;

  useEffect(() => {
    if (modalName) {
      document.body.style.overflowY = "hidden";
    } else if (!modalName) {
      document.body.style.overflowY = "auto";
    }
  }, [modalName]);

  if (!ModalComponent) {
    return null;
  }

  return (
    <AnimatePresence>
      {ModalComponent && (
        <ModalWrapper hideModalHandler={onOutsideClick} id="modal">
          <ModalComponent />
        </ModalWrapper>
      )}
    </AnimatePresence>
  );
};

export default Modal;
